import { Dispatch, AnyAction } from 'redux'
import axios, { makeRequest } from '../../utils/axios'

export const ROLES_GET = 'ROLES_GET'

export const rolesGet = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/roles'),
  })

  if (getResponse) {
    dispatch({
      type: ROLES_GET,
      payload: getResponse.data,
    })
  }
}
