import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Ranking, RankingSorting } from './types'

export const ADD_SORTING_TO_RANKING = 'ADD_SORTING_TO_RANKING'
export const REMOVE_SORTING_FROM_RANKING = 'REMOVE_SORTING_FROM_RANKING'

export const addSortingToRanking = (
  rankingId: Ranking['id'],
  rankingSorting: RankingSorting
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/rankings/${rankingId}/sorting`, [
        {
          parameterId: rankingSorting.parameterId,
        },
      ]),
    successText: 'Column added to ranking sorting',
  })

  if (createResponse) {
    dispatch({
      type: ADD_SORTING_TO_RANKING,
      payload: rankingSorting,
    })
  }
}

export const removeSortingFromRanking = (
  rankingId: Ranking['id'],
  parameterId: RankingSorting['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/rankings/${rankingId}/sorting/${parameterId}`),
    successText: 'Column removed from ranking sorting',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_SORTING_FROM_RANKING,
      payload: {
        parameterId,
      },
    })
  }
}
