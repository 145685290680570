import React from 'react'
import { Helmet } from 'react-helmet'

type Props = {
  title?: string
  children?: React.ReactNode
  className?: string
}

const Page: React.FC<Props> = ({ title, children, className }) => (
  <div className={className}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </div>
)

export default Page
