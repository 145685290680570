import React, { Suspense } from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'

import { renderRoutes, RouteConfig } from 'react-router-config'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}))

const Error: React.SFC<{ route: RouteConfig }> = (props) => {
  const { route } = props

  const classes = useStyles()

  return (
    <main className={classes.root}>
      <Suspense fallback={<LinearProgress />}>
        {renderRoutes(route.routes)}
      </Suspense>
    </main>
  )
}

export default Error
