import validate from 'validate.js'

type Options = {
  message: string
  pattern: RegExp
}

const regex = (value: string, options: Options): string | undefined => {
  const regExp = new RegExp(options.pattern)

  if (!regExp.test(value)) {
    return options.message
  }
}

const checked = (value: boolean, options: Options): string | undefined => {
  if (value !== true) {
    return options.message || 'must be checked'
  }
}

validate.validators = {
  ...validate.validators,
  regex,
  checked,
}
