import { combineReducers } from 'redux'
import entitiesReducer from '../components/Entities/entitiesReducer'
import entityReducer from '../components/Entities/entityReducer'
import notificationsReducer from '../components/Notifier/notificationsReducer'
import countingCostsTypesReducer from '../components/Offers/countingCostsTypesReducer'
import offerReducer from '../components/Offers/offerReducer'
import offersCountingCostsReducer from '../components/Offers/offersCountingCostsReducer'
import offersParametersReducer from '../components/Offers/offersParametersReducer'
import offersReducer from '../components/Offers/offersReducer'
import servicesReducer from '../components/Offers/servicesReducer'
import rolesReducer from '../layouts/Dashboard/rolesReducer'
import affiliatesReducer from '../views/Affiliates/affiliatesReducer'
import agreementReducer from '../views/Agreements/agreementReducer'
import agreementsReducer from '../views/Agreements/agreementsReducer'
import branchesReducer from '../views/Branches/branchesReducer'
import debtorReducer from '../views/DebtorsBases/debtorReducer'
import debtorsBasesReducer from '../views/DebtorsBases/debtorsBasesReducer'
import entityFormInputReducer from '../views/EntityFormInputs/entityFormInputReducer'
import entityFormInputsReducer from '../views/EntityFormInputs/entityFormInputsReducer'
import formReducer from '../views/Forms/formReducer'
import formsReducer from '../views/Forms/formsReducer'
import languagesReducer from '../views/Languages/languagesReducer'
import sessionReducer from '../views/Login/sessionReducer'
import websitesReducer from '../views/Overview/websitesReducer'

import parameterReducer from '../views/Parameters/parameterReducer'
import parametersReducer from '../views/Parameters/parametersReducer'
import productCategoriesReducer from '../views/ProductCategories/productCategoriesReducer'
import productCategoryReducer from '../views/ProductCategories/productCategoryReducer'
import productReducer from '../views/Products/productReducer'
import productsReducer from '../views/Products/productsReducer'
import rankingReducer from '../views/Rankings/rankingReducer'
import rankingsReducer from '../views/Rankings/rankingsReducer'
import currentUserReducer from '../views/Users/currentUserReducer'
import userReducer from '../views/Users/userReducer'
import usersReducer from '../views/Users/usersReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  roles: rolesReducer,
  notifications: notificationsReducer,
  websites: websitesReducer,
  debtor: debtorReducer,
  debtorsBases: debtorsBasesReducer,
  currentUser: currentUserReducer,
  users: usersReducer,
  user: userReducer,
  productCategory: productCategoryReducer,
  productCategories: productCategoriesReducer,
  branches: branchesReducer,
  entityFormInputs: entityFormInputsReducer,
  entityFormInput: entityFormInputReducer,
  entities: entitiesReducer,
  entity: entityReducer,
  parameter: parameterReducer,
  parameters: parametersReducer,
  offer: offerReducer,
  offers: offersReducer,
  offersParameters: offersParametersReducer,
  countingCostsTypes: countingCostsTypesReducer,
  offersCountingCosts: offersCountingCostsReducer,
  ranking: rankingReducer,
  rankings: rankingsReducer,
  product: productReducer,
  products: productsReducer,
  form: formReducer,
  forms: formsReducer,
  agreement: agreementReducer,
  agreements: agreementsReducer,
  services: servicesReducer,
  languages: languagesReducer,
  affiliates: affiliatesReducer,
})

export default rootReducer
