import { AnyAction, Dispatch } from 'redux'
import StoreTypes from '../../types/StoreType'

import axios, { makeRequest } from '../../utils/axios'
import { resetDataAfterFail } from './rankingOffersActions'
import { Ranking, RankingNote } from './types'

export const RANKINGS_GET = 'RANKINGS_GET'
export const RANKINGS_OFFERS_GET = 'RANKINGS_OFFERS_GET'
export const RANKING_ADD = 'RANKING_ADD'
export const RANKING_REMOVE = 'RANKING_REMOVE'
export const LAST_ADDED_RANKING_ID = 'LAST_ADDED_RANKING_ID'
export const RANKING_NOTE_ADD = 'RANKING_NOTE_ADD'

export const getAllRankings = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/rankings?relations=rankingsNotes'),
  })

  if (getResponse) {
    dispatch({
      type: RANKINGS_GET,
      payload: getResponse.data,
    })
  }
}

export const getAllRankingsOffers = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/rankings?relations=offers'),
  })

  if (getResponse) {
    dispatch({
      type: RANKINGS_OFFERS_GET,
      payload: getResponse.data,
    })
  }
}

export const updateOffersOnRanking = (
  rankingId: Ranking['id'],
  rankingOffers: Ranking['rankingOffers']
) => async (dispatch: Dispatch<AnyAction>, getState: () => StoreTypes) => {
  const rankingOffersList =
    rankingOffers &&
    rankingOffers.map((offer, index) => ({
      priority: index,
      offerId: offer.id,
    }))

  await makeRequest({
    axiosRequest: () =>
      axios.patch(`/rankings/${rankingId}/offers`, rankingOffersList),
    successText: ' ranking offers order updated',
    errorCallback: resetDataAfterFail(dispatch, getState),
  })
}

export const addRanking = (ranking: Ranking) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/rankings', {
        name: ranking.name,
      }),
    successText: ' ranking added',
  })

  if (createResponse) {
    dispatch({
      type: RANKING_ADD,
      payload: createResponse.data,
    })
    dispatch({
      type: LAST_ADDED_RANKING_ID,
      payload: createResponse.data.id,
    })
  }
}

export const removeRanking = (rankingId: Ranking['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/rankings/${rankingId}`),
    successText: ' ranking removed',
  })

  if (deleteResponse) {
    dispatch({
      type: RANKING_REMOVE,
      payload: rankingId,
    })
  }
}

export const copyRanking = (rankingId: Ranking['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () => axios.post(`/rankings/${rankingId}/copy`),
    successText: ' ranking copied',
  })

  if (createResponse) {
    dispatch({
      type: RANKING_ADD,
      payload: createResponse.data,
    })
    dispatch({
      type: LAST_ADDED_RANKING_ID,
      payload: createResponse.data.id,
    })
  }
}

export const addRankingNote = (
  rankingId: Ranking['id'],
  content: RankingNote['content']
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/rankings/${rankingId}/note`, {
        content,
      }),
    successText: ' ranking note updated',
  })

  if (createResponse) {
    const getResponse = await makeRequest({
      axiosRequest: () => axios.get(`/rankings/${rankingId}/note`),
    })

    if (getResponse) {
      dispatch({
        type: RANKING_NOTE_ADD,
        payload: getResponse.data,
      })
    }
  }
}
