import { applyMiddleware, compose, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './rootReducer'

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware] // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  )

  const enhancers = [middlewareEnhancer]

  return createStore(rootReducer, preloadedState, compose(...enhancers))
}
