import {
  getOneFromApi,
  initialFormState,
  InitialFormStateType,
} from '../../utils/baseReducers'
import {
  DICTIONARY_DATA_ADD,
  DICTIONARY_DATA_REMOVE,
  DICTIONARY_DATA_UPDATE,
} from './dictionaryDataActions'
import { ENTITY_FORM_INPUT_GET } from './entityFormInputsActions'
import { DictionaryData, EntityFormInputActionsTypes } from './types'

const entityFormInputReducer = (
  state: InitialFormStateType = initialFormState,
  action: EntityFormInputActionsTypes
) => {
  switch (action.type) {
    case ENTITY_FORM_INPUT_GET: {
      return getOneFromApi(state, action.payload)
    }
    case DICTIONARY_DATA_ADD: {
      const dictionaryData = state.data.dictionaryData || []
      const newData = {
        ...state.data,
        dictionaryData: dictionaryData.concat({
          ...action.payload,
        }),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case DICTIONARY_DATA_REMOVE: {
      return {
        ...state,
        data: {
          ...state.data,
          dictionaryData: [...state.data.dictionaryData].filter(
            (item) => parseInt(action.payload.toString()) !== item.languageId
          ),
        },
      }
    }

    case DICTIONARY_DATA_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          dictionaryData: state.data.dictionaryData.map(
            (dictionaryData: DictionaryData) =>
              dictionaryData.languageId === action.payload.languageId
                ? action.payload
                : dictionaryData
          ),
        },
      }
    }

    default: {
      return state
    }
  }
}

export default entityFormInputReducer
