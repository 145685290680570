import { getAccessToken } from 'axios-jwt'
import jwt from 'jsonwebtoken'
import { TokenActionsTypes } from './types'
import { SESSION_LOGIN, SESSION_LOGOUT } from './sessionActions'

export const initialState = {
  user: jwt.decode(getAccessToken() as string) || {},
}

const sessionReducer = (state = initialState, action: TokenActionsTypes) => {
  switch (action.type) {
    case SESSION_LOGIN: {
      return {
        ...state,
        user: { ...action.payload },
      }
    }

    case SESSION_LOGOUT: {
      return {
        ...state,
        user: {},
      }
    }

    default: {
      return state
    }
  }
}

export default sessionReducer
