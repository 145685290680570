import React from 'react'
import Rollbar from 'rollbar'

const ErrorHeding: React.SFC = () => <h1>Something went wrong</h1>

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError = () => ({ hasError: true })

  componentDidCatch(error: Error) {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_ROLLBAR_TOKEN
    ) {
      const rollbar = new Rollbar({
        accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
      })

      rollbar.error(error)
    }
  }

  render() {
    if (this.state.hasError) {
      return ErrorHeding
    }
    return this.props.children
  }
}

export default ErrorBoundary
