import {
  ENTITY_GET,
  ENTITY_UPDATE,
  ENTITY_LOGO_UPDATE,
  RESET_ENTITY,
} from './entityActions'
import {
  getOneFromApi,
  initialFormState,
  updateOneFromApi,
  InitialFormStateType,
} from '../../utils/baseReducers'
import { EntityActionsTypes, EntityFormValues } from './types'

const entityReducer = (
  state: InitialFormStateType = initialFormState,
  action: EntityActionsTypes
) => {
  switch (action.type) {
    case ENTITY_GET: {
      const entity = {
        ...action.payload,
      }
      const mappedValues =
        (action.payload.entitiesData &&
          action.payload.entitiesData.map((item: EntityFormValues) => ({
            id: item.id,
            dataId: item.dataId,
            slug: item.data.slug,
            name: item.data.name,
            value: item.value,
          }))) ||
        []
      entity.entitiesData = [
        {
          name: 'Name',
          slug: 'name',
          value: action.payload.name,
        },
        ...mappedValues,
      ] as EntityFormValues[]

      return getOneFromApi(state, entity)
    }

    case ENTITY_UPDATE: {
      const mappedEntity = {
        ...state.data,
        entitiesData: action.payload,
      }

      return updateOneFromApi(state, mappedEntity)
    }

    case ENTITY_LOGO_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
        loaded: true,
      }
    }

    case RESET_ENTITY: {
      return initialFormState
    }

    default: {
      return state
    }
  }
}

export default entityReducer
