import React, { useEffect, useState } from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import {
  Card,
  CardContent,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import { ClassNameMap } from '@material-ui/styles'
import axios from 'axios'

import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import { makeRequest } from '../../utils/axios'
import { Site } from './types'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  tableBoldFont: {
    fontWeight: 'bold',
  },
  tableError: {
    background: '#F45B69',
  },
  flexCell: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

const SitesStatus = () => {
  const classes = useStyles()
  const [websitesInfo, setWebsitesInfo] = useState<Site[]>([])
  const API_URL = process.env.REACT_APP_UPDOWN_IO_API_URL
  const uptimeClass = (uptime: number) =>
    uptime < 100 ? classes.tableBoldFont : ''
  const statusClass = (lastStatus: number) =>
    lastStatus !== 200 ? classes.tableError : ''
  const createTooltipInfo = (text: string) => (
    <Tooltip title={text}>
      <IconButton aria-label="delete">
        <HelpIcon />
      </IconButton>
    </Tooltip>
  )

  if (!API_URL) {
    throw new Error('API_URL is not defined')
  }

  useEffect(() => {
    let mounted = true
    const getWebsitesInfo = async () => {
      if (mounted) {
        const response = await makeRequest({
          axiosRequest: () => axios.get(API_URL),
        })

        setWebsitesInfo(response.data)
      }
    }

    getWebsitesInfo()

    return () => {
      mounted = false
    }
  }, [API_URL])

  return (
    <Page className={classes.root} title="Sites Status">
      <PageHeader pageName="Sites status" />
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div className={classes.websitesTable}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Websites</TableCell>
                  <TableCell>Last status</TableCell>
                  <TableCell>SSL</TableCell>
                  <TableCell>Up time %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {websitesInfo &&
                  websitesInfo.map((website: Site) => (
                    <TableRow
                      key={website.token}
                      className={statusClass(website.last_status)}
                    >
                      <TableCell className={classes.flexCell}>
                        <Typography>
                          <Link
                            href={website.url}
                            target="_BLANK"
                            className={classes.link}
                            variant="body2"
                          >
                            {website.url}
                          </Link>
                        </Typography>
                        {website.error && createTooltipInfo(website.error)}
                      </TableCell>
                      <TableCell>{website.last_status}</TableCell>
                      <TableCell>
                        {website.ssl && website.ssl.valid ? 'Active' : 'Error'}
                      </TableCell>
                      <TableCell className={uptimeClass(website.uptime)}>
                        {website.uptime}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </Page>
  )
}

export default SitesStatus
