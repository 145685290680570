import {
  getOneFromApi,
  initialFormState,
  InitialFormStateType,
} from '../../utils/baseReducers'
import {
  ADD_BRANCH_TO_PARAMETER,
  REMOVE_BRANCH_FROM_PARAMETER,
} from './branchesParametersActions'
import {
  DICTIONARY_PARAMETER_ADD,
  DICTIONARY_PARAMETER_REMOVE,
  DICTIONARY_PARAMETER_UPDATE,
} from './dictionaryParameterActions'
import { PARAMETER_GET } from './parametersActions'
import {
  BranchParameter,
  DictionaryParameter,
  ParameterActionsTypes,
} from './types'

const parameterReducer = (
  state: InitialFormStateType = initialFormState,
  action: ParameterActionsTypes
) => {
  switch (action.type) {
    case PARAMETER_GET: {
      return getOneFromApi(state, action.payload)
    }

    case ADD_BRANCH_TO_PARAMETER: {
      const branches = state.data.branchesParameters || []

      const newData = {
        ...state.data,
        branchesParameters: branches.concat({
          ...action.payload,
          branchId: action.payload.branch.id,
        }),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_BRANCH_FROM_PARAMETER: {
      return {
        ...state,
        data: {
          ...state.data,
          branchesParameters: state.data.branchesParameters.filter(
            (branchParameter: BranchParameter) =>
              parseInt((branchParameter.branchId as unknown) as string, 10) !==
              parseInt((action.payload.branchId as unknown) as string, 10)
          ),
        },
      }
    }

    case DICTIONARY_PARAMETER_ADD: {
      const dictionaryParameters = state.data.dictionaryParameters || []
      const newData = {
        ...state.data,
        dictionaryParameters: dictionaryParameters.concat({
          ...action.payload,
        }),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case DICTIONARY_PARAMETER_REMOVE: {
      return {
        ...state,
        data: {
          ...state.data,
          dictionaryParameters: [...state.data.dictionaryParameters].filter(
            (item) => parseInt(action.payload.toString()) !== item.languageId
          ),
        },
      }
    }

    case DICTIONARY_PARAMETER_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          dictionaryParameters: state.data.dictionaryParameters.map(
            (dictionaryParameter: DictionaryParameter) =>
              dictionaryParameter.languageId === action.payload.languageId
                ? action.payload
                : dictionaryParameter
          ),
        },
      }
    }

    default: {
      return state
    }
  }
}

export default parameterReducer
