import {
  OFFER_ADD,
  OFFER_REMOVE,
  OFFERS_GET,
  RESET_OFFERS,
} from './offersActions'
import {
  add,
  getAll,
  initialState,
  remove,
  InitialStateType,
} from '../../utils/baseReducers'
import { OffersActionsTypes } from './types'

const offersReducer = (
  state: InitialStateType = initialState,
  action: OffersActionsTypes
) => {
  switch (action.type) {
    case OFFERS_GET: {
      return getAll(state, action.payload)
    }

    case OFFER_ADD: {
      return add(state, action.payload)
    }

    case OFFER_REMOVE: {
      return remove(state, action.payload)
    }

    case RESET_OFFERS: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default offersReducer
