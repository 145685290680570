import { USER_ADD, USER_REMOVE, USERS_GET } from './usersActions'
import { add, getAll, initialState, update } from '../../utils/baseReducers'

import { UsersActionsTypes } from './types'

const usersReducer = (state = initialState, action: UsersActionsTypes) => {
  switch (action.type) {
    case USERS_GET: {
      return getAll(state, action.payload)
    }

    case USER_ADD: {
      return add(state, action.payload)
    }

    case USER_REMOVE: {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              isActive: action.payload.isActive,
            }
          }

          return item
        }),
      }
    }

    default: {
      return state
    }
  }
}

export default usersReducer
