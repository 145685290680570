import { isNull, omitBy, omit } from 'lodash'
import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Offer } from './types'
import { Branch } from '../../views/Branches/types'
import { dispatchResetReducer } from '../../utils/baseReducers'

export const OFFER_ADD = 'OFFER_ADD'
export const OFFERS_GET = 'OFFERS_GET'
export const OFFER_REMOVE = 'OFFER_REMOVE'
export const RESET_OFFERS = 'RESET_OFFERS'

export const getAllOffers = (withRelations: boolean) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatchResetReducer(dispatch, RESET_OFFERS)
  const offersUrl =
    (withRelations && '/offers/?relations=parameter,product,entity') ||
    '/offers'
  const response = await makeRequest({
    axiosRequest: () => axios.get(offersUrl),
  })

  if (response) {
    dispatch({
      type: OFFERS_GET,
      payload: response.data,
    })
  }
}

export const getAllOffersRelatedToBranch = (
  branchId: Branch['id'],
  withRelations: boolean
) => async (dispatch: Dispatch<AnyAction>) => {
  dispatchResetReducer(dispatch, RESET_OFFERS)
  const offersUrl =
    (withRelations && '/offers?relations=product,offersParameters') ||
    `/branches/${branchId}/offers?relations=product,offersParameters`
  const response = await makeRequest({
    axiosRequest: () => axios.get(offersUrl),
  })

  if (response) {
    dispatch({
      type: OFFERS_GET,
      payload: response.data,
    })
  }
}

export const addOffer = (offer: Offer) => {
  const offerData = omit(omitBy(offer, isNull), 'rate')

  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () => axios.post('/offers', offerData),
      successText: 'Offer added',
    })

    if (createResponse) {
      dispatch({
        type: OFFER_ADD,
        payload: createResponse.data,
      })
    }
  }
}

export const removeOffer = (offerId: Offer['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/offers/${offerId}`),
    successText: 'Offer removed',
  })

  if (deleteResponse) {
    dispatch({
      type: OFFER_REMOVE,
      payload: offerId,
    })
  }
}
