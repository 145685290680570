import { Dispatch, AnyAction } from 'redux'
import { get } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'
import { Ranking, RankingOffer } from './types'
import StoreTypes from '../../types/StoreType'
import { getRankingAllParams } from './rankingActions'

export const ADD_OFFERS_TO_RANKING = 'ADD_OFFERS_TO_RANKING'
export const REMOVE_OFFER_FROM_RANKING = 'REMOVE_OFFER_FROM_RANKING'
export const UPDATE_RANKING_OFFERS_LIST = 'UPDATE_RANKING_OFFERS_LIST'
export const UPDATE_RANKING_OFFER_TAG = 'UPDATE_RANKING_OFFER_TAG'

export const resetDataAfterFail = (
  dispatch: Dispatch<AnyAction>,
  getState: () => StoreTypes
) => {
  const state = getState()
  const prevRankingOffersList = get(state.ranking.data, 'rankingOffers', [])

  const rollBackPrevListAfterReqFail = () => {
    dispatch({
      type: UPDATE_RANKING_OFFERS_LIST,
      payload: {
        list: prevRankingOffersList,
      },
    })
  }

  return rollBackPrevListAfterReqFail
}

export const addOffersToRanking = (
  rankingId: Ranking['id'],
  offers: RankingOffer[],
  latestPrority: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => async (dispatch: Dispatch<any>, getState: () => StoreTypes) => {
  let currentPriorit = latestPrority
  // eslint-disable-next-line no-plusplus
  const mappedOffersList = offers.map((offer) => ({
    offerId: offer.id,
    priority: currentPriorit++,
  }))
  dispatch({
    type: ADD_OFFERS_TO_RANKING,
    payload: { offers, latestPrority },
  })

  await makeRequest({
    axiosRequest: () =>
      axios.post(`/rankings/${rankingId}/offers`, mappedOffersList),
    successText: ' ranking offers add',
    errorCallback: resetDataAfterFail(dispatch, getState),
  })

  dispatch(getRankingAllParams(rankingId))
}

export const removeOfferFromRanking = (
  rankingId: Ranking['id'],
  offerId: RankingOffer['id']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => async (dispatch: Dispatch<any>, getState: () => StoreTypes) => {
  dispatch({
    type: REMOVE_OFFER_FROM_RANKING,
    payload: {
      offerId,
    },
  })

  await makeRequest({
    axiosRequest: () => axios.delete(`rankings/${rankingId}/offers/${offerId}`),
    successText: ' ranking offers deleted',
    errorCallback: resetDataAfterFail(dispatch, getState),
  })

  dispatch(getRankingAllParams(rankingId))
}

export const updateRankingOffers = (
  rankingId: Ranking['id'],
  list: RankingOffer[]
) => async (dispatch: Dispatch<AnyAction>, getState: () => StoreTypes) => {
  const priorityList = list.map((offer, index) => ({
    priority: index,
    offerId: offer.offerId,
    isFixedPriority: offer.isFixedPriority,
    isSponsored: offer.isSponsored,
  }))
  dispatch({
    type: UPDATE_RANKING_OFFERS_LIST,
    payload: {
      list,
    },
  })

  await makeRequest({
    axiosRequest: () =>
      axios.patch(`/rankings/${rankingId}/offers`, priorityList),
    successText: ' ranking offers order updated',
    errorCallback: resetDataAfterFail(dispatch, getState),
  })
}

export const updateRankingOfferTagAndIsSponsored = (
  rankingId: Ranking['id'],
  offer: RankingOffer
) => async (dispatch: Dispatch<AnyAction>, getState: () => StoreTypes) => {
  dispatch({
    type: UPDATE_RANKING_OFFER_TAG,
    payload: {
      offerId: offer.offerId,
      tag: offer.tag,
      isSponsored: offer.isSponsored,
    },
  })

  await makeRequest({
    axiosRequest: () =>
      axios.patch(`/rankings/${rankingId}/offers`, [
        {
          offerId: offer.offerId,
          tag: offer.tag,
          isSponsored: offer.isSponsored,
        },
      ]),
    successText: ' ranking offers order updated',
    errorCallback: resetDataAfterFail(dispatch, getState),
  })
}
