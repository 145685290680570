import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from '../../views/Users/currentUserActions'

import { getAllUsers } from '../../views/Users/usersActions'
import { getUser } from '../../views/Users/userActions'
import StoreType from '../../types/StoreType'
import { User } from '../../views/Users/types'

export function useUsersList() {
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector((state: StoreType) => state.users)

  useEffect(() => {
    const fetchUsers = () => dispatch(getAllUsers())

    fetchUsers()
  }, [dispatch])

  return { users: data, isLoading }
}

export function useUser(id: User['id']) {
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector((state: StoreType) => state.user)

  useEffect(() => {
    const fetchUser = () => dispatch(getUser(id))

    fetchUser()
  }, [dispatch, id])

  return { user: data, isLoading }
}

export function useCurrentUser() {
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector(
    (state: StoreType) => state.currentUser
  )

  useEffect(() => {
    const fetchCurrentUser = () => dispatch(getCurrentUser())

    fetchCurrentUser()
  }, [dispatch])

  return { currentUser: data, isLoading }
}
