import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { Language } from './types'

export const LANGUAGE_GET = 'LANGUAGE_GET'
export const LANGUAGES_GET = 'LANGUAGES_GET'

export const getAllLanguages = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/languages'),
  })

  if (getResponse) {
    dispatch({
      type: LANGUAGES_GET,
      payload: getResponse.data,
    })
  }
}

export const getLanguage = (languageId: Language['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/languages/${languageId}`),
  })

  if (getResponse) {
    dispatch({
      type: LANGUAGE_GET,
      payload: getResponse.data,
    })
  }
}
