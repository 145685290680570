import React from 'react'
import { Typography, Theme, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import classNames from 'classnames'

import useRouter from '../../utils/useRouter'
import NavigationList from './NavigationList'
import { Page } from './types'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))

type Props = {
  className?: string
  component: React.SFC<{ className: string }> | 'nav' | 'div'
  pages: Page[]
  title?: string
}

const Navigation: React.SFC<Props> = ({
  title,
  pages,
  className,
  component: Component = 'nav',
}) => {
  const classes = useStyles()
  const router = useRouter()

  return (
    <Component className={classNames(classes.root, className)}>
      {title && <Typography variant="overline">{title}</Typography>}
      <NavigationList depth={0} pages={pages} router={router} />
    </Component>
  )
}

export default Navigation
