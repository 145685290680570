import { PRODUCT_ADD, PRODUCT_REMOVE, PRODUCTS_GET } from './productsActions'
import {
  add,
  getAll,
  initialState,
  remove,
  InitialStateType,
} from '../../utils/baseReducers'
import { ProductsActions } from './types'

const productsReducer = (
  state: InitialStateType = initialState,
  action: ProductsActions
) => {
  switch (action.type) {
    case PRODUCTS_GET: {
      return getAll(state, action.payload)
    }

    case PRODUCT_ADD: {
      return add(state, action.payload)
    }

    case PRODUCT_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default productsReducer
