import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'

import { Form } from './types'

export const FORM_GET = 'FORM_GET'

export const getForm = (formId: Form['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/forms/${formId}`),
  })

  if (getResponse) {
    dispatch({
      type: FORM_GET,
      payload: getResponse.data,
    })
  }
}
