import {
  add,
  getAll,
  initialState,
  InitialStateType,
  remove,
  update,
} from '../../utils/baseReducers'
import {
  PARAMETER_ADD,
  PARAMETER_REMOVE,
  PARAMETER_UPDATE,
  PARAMETERS_GET,
} from './parametersActions'
import { ParametersActionsTypes } from './types'

const parametersReducer = (
  state: InitialStateType = initialState,
  action: ParametersActionsTypes
) => {
  switch (action.type) {
    case PARAMETERS_GET: {
      return getAll(state, action.payload)
    }

    case PARAMETER_ADD: {
      return add(state, action.payload)
    }

    case PARAMETER_UPDATE: {
      return update(state, action.payload)
    }

    case PARAMETER_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default parametersReducer
