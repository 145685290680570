import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StoreType from '../../types/StoreType'
import { getAllWebsites } from '../../views/Overview/websitesActions'

export function useWebsiteList() {
  const dispatch = useDispatch()
  const { data, isLoading } = useSelector((state: StoreType) => state.websites)

  useEffect(() => {
    const fetchWebsite = () => dispatch(getAllWebsites())

    fetchWebsite()
  }, [dispatch])

  return { websites: data, isLoading }
}
