import React from 'react'
import { Drawer, Hidden, Paper, Theme, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import classNames from 'classnames'

import Navigation from '../../components/Navigation'
import navigationConfig from './navigationConfig'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}))

type Props = {
  openMobile: boolean
  onMobileClose: (
    event: Record<string, unknown>,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => void
  className: string
}

const NavBar: React.SFC<Props> = ({ openMobile, onMobileClose, className }) => {
  const classes = useStyles()
  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig.map((list) => (
          <Navigation
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  )

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div className={classNames(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          className={classNames(classes.root, className)}
          elevation={1}
          square
        >
          {navbarContent}
        </Paper>
      </Hidden>
    </>
  )
}

export default NavBar
