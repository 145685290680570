import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'

export const WEBSITES_GET = 'WEBSITES_GET'

export const getAllWebsites = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/websites'),
  })

  if (getResponse) {
    dispatch({
      type: WEBSITES_GET,
      payload: getResponse.data,
    })
  }
}
