import { Dispatch, AnyAction } from 'redux'
import { isNull, omit, omitBy } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'
import { Product } from './types'

export const PRODUCT_GET = 'PRODUCT_GET'
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE'

export const getProduct = (productId: Product['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(`/products/${productId}?relations=tabs,dictionaryTabs`),
  })

  if (getResponse) {
    dispatch({
      type: PRODUCT_GET,
      payload: getResponse.data,
    })
  }
}

export const updateProduct = (productId: Product['id'], product: Product) => {
  const productData = omit(omitBy(product, isNull), [
    'productParameters',
    'productDebtorsBases',
    'tableData',
    'productsTypes',
  ])

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () => axios.patch(`/products/${productId}`, productData),
      successText: 'Product updated',
    })

    if (updateResponse) {
      dispatch({
        type: PRODUCT_UPDATE,
        payload: product,
      })
    }
  }
}
