import {
  AppBar,
  Button,
  Hidden,
  makeStyles,
  Theme,
  Toolbar,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import { ClassNameMap } from '@material-ui/styles'
import classNames from 'classnames'
import React, { MouseEvent } from 'react'
import { useDispatch } from 'react-redux'
import AssistantLogo from '../../components/AssistantLogo'
import useRouter from '../../utils/useRouter'

import { logout } from '../../views/Login/sessionActions'
import EditAccountButton from './TopBar/EditAccountButton'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    background:
      process.env.REACT_APP_IS_TEST_ENV === 'true' ? 'red' : undefined,
  },
  flexGrow: {
    flexGrow: 1,
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
  },
  menuButton: {
    color: theme.palette.white,
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
}))

type Props = {
  className?: string
  onOpenNavBarMobile: (event: MouseEvent<HTMLButtonElement>) => void
}

const TopBar: React.SFC<Props> = ({ onOpenNavBarMobile, className }) => {
  const classes = useStyles()
  const { history } = useRouter()
  const dispatch = useDispatch()

  const handleLogout = async () => {
    await dispatch(logout())

    history.push('/auth/login')
  }

  return (
    <AppBar className={classNames(classes.root, className)} color="primary">
      <Toolbar>
        <AssistantLogo />
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <EditAccountButton />
          <Button className={classes.logoutButton} onClick={handleLogout}>
            <InputIcon className={classes.logoutIcon} />
            Sign out
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            onClick={onOpenNavBarMobile}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
