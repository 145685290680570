import {
  getAll,
  initialState,
  InitialStateType,
} from '../../utils/baseReducers'
import { RolesActionsTypes } from './types'
import { ROLES_GET } from './rolesActions'

const rolesReducer = (
  state: InitialStateType = initialState,
  action: RolesActionsTypes
) => {
  switch (action.type) {
    case ROLES_GET: {
      return getAll(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default rolesReducer
