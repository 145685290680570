import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { dispatchResetReducer } from '../../utils/baseReducers'
import { Offer, OfferParameter } from './types'

export const OFFER_PARAMETER_UPDATE = 'OFFER_PARAMETER_UPDATE'
export const OFFERS_PARAMETERS_GET = 'OFFERS_PARAMETERS_GET'
export const RESET_OFFERS_PARAMETERS = 'RESET_OFFERS_PARAMETERS'

export const getOffersParameters = (
  offersIds: Offer['id'][],
  serviceId: number | null
) => async (dispatch: Dispatch<AnyAction>) => {
  dispatchResetReducer(dispatch, RESET_OFFERS_PARAMETERS)
  const ids = offersIds.join(',')
  const response = await makeRequest({
    axiosRequest: () => axios.get(`/offers/parameters?serviceId=${serviceId}&ids=${ids}`),
  })

  if (response) {
    dispatch({
      type: OFFERS_PARAMETERS_GET,
      payload: response.data,
    })
  }
}

export const updateOfferParameter = (
  offerParameter: OfferParameter,
) => async (dispatch: Dispatch<AnyAction>) => {
  const { offerId, parameterId, serviceId, value } = offerParameter

  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/offers/${offerId}/parameter`, {
        value,
        parameterId,
        serviceId: serviceId === null ? undefined : serviceId,
      }),
    successText: 'OfferParameter updated',
  })

  if (updateResponse) {
    dispatch({
      type: OFFER_PARAMETER_UPDATE,
      payload: offerParameter,
    })
  }
}
