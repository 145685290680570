import {
  initialState as baseInitialState,
  InitialStateType,
} from '../../utils/baseReducers'
import {
  LAST_ADDED_RANKING_ID,
  RANKING_ADD,
  RANKING_NOTE_ADD,
  RANKING_REMOVE,
  RANKINGS_GET,
  RANKINGS_OFFERS_GET,
} from './rankingsActions'
import { RankingsActionsTypes } from './types'

export const initialState = {
  ...baseInitialState,
  lastAddedRankingId: null,
}

const rankingsReducer = (
  state: InitialStateType = initialState,
  action: RankingsActionsTypes
) => {
  switch (action.type) {
    case RANKINGS_GET: {
      return {
        ...state,
        loaded: true,
        isLoading: false,
        data: action.payload,
      }
    }

    case RANKINGS_OFFERS_GET: {
      return {
        ...state,
        loaded: true,
        isLoading: false,
        data: Object.assign(
          [],
          ...action.payload.map((ranking) => ({
            [ranking.rankingId]: {
              id: ranking.rankingId,
              name: ranking.rankingName,
              isTop: ranking.isTop,
              rankingOffers: action.payload
                .filter(
                  (rankingOffer) => rankingOffer.rankingId === ranking.rankingId
                )
                .map((rankingOffer: any) => ({
                  id: rankingOffer.offerId,
                  name: rankingOffer.offerName,
                  priority: rankingOffer.offerPriority,
                })),
            },
          }))
        )
          .sort((a: any, b: any) => (a.name < b.name ? -1 : 1))
          .sort((a: any, b: any) => {
            if (a.name.match(/\[.*\]/)) {
              return 1
            }

            if (!a.name.match(/\[.*\]/)) {
              return -1
            }
          }),
      }
    }

    case RANKING_ADD: {
      return {
        ...state,
        data: [...state.data, action.payload],
      }
    }

    case RANKING_NOTE_ADD: {
      return {
        ...state,
        data: state.data.map((ranking) => {
          if (ranking.id === action.payload.rankingId) {
            return {
              ...ranking,
              rankingsNotes: [action.payload, ...ranking.rankingsNotes],
            }
          }

          return ranking
        }),
      }
    }

    case LAST_ADDED_RANKING_ID: {
      return {
        ...state,
        lastAddedRankingId: action.payload,
      }
    }

    case RANKING_REMOVE: {
      return {
        ...state,
        data: state.data.filter((ranking) => ranking.id !== action.payload),
      }
    }

    default: {
      return state
    }
  }
}

export default rankingsReducer
