import HomeIcon from '@material-ui/icons/HomeOutlined'
import HttpIcon from '@material-ui/icons/Http'
import LocalAtmIcon from '@material-ui/icons/LocalAtm'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer'
import SubtitlesIcon from '@material-ui/icons/Subtitles'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import VpnLockIcon from '@material-ui/icons/VpnLock'
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined'
import FolderIcon from '@material-ui/icons/Folder'

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Overview',
        href: '/overview',
        icon: HomeIcon,
      },
      {
        title: 'Sites Status',
        href: '/sites-status',
        icon: HttpIcon,
      },
      {
        title: 'Rankings',
        href: '/rankings',
        icon: FormatListNumberedIcon,
        children: [
          {
            title: 'List',
            href: '/rankings',
          },
          {
            title: 'Edit all',
            href: '/rankings/edit-all',
          },
        ],
      },
      {
        title: 'Lenders',
        href: '/lenders',
        icon: LocalAtmIcon,
        children: [
          {
            title: 'List',
            href: '/lenders',
          },
          {
            title: 'Offers',
            href: '/lenders/offers',
          },
          {
            title: 'Offers Parameters',
            href: '/lenders/offers-parameters',
          },
          {
            title: 'Debtors Bases',
            href: '/lenders/debtors-bases',
          },
        ],
      },
      {
        title: 'Banks',
        href: '/banks',
        icon: AccountBalanceOutlinedIcon,
        children: [
          {
            title: 'List',
            href: '/banks',
          },
          {
            title: 'Offers',
            href: '/banks/offers',
          },
          {
            title: 'Offers Parameters',
            href: '/banks/offers-parameters',
          },
        ],
      },
      {
        title: 'Vpns',
        href: '/vpns',
        icon: VpnLockIcon,
        children: [
          {
            title: 'List',
            href: '/vpns',
          },
          {
            title: 'Offers',
            href: '/vpns/offers',
          },
          {
            title: 'Offers Parameters',
            href: '/vpns/offers-parameters',
          },
        ],
      },
      {
        title: 'Bookmakers',
        href: '/bookmakers',
        icon: SportsSoccerIcon,
        children: [
          {
            title: 'List',
            href: '/bookmakers',
          },
          {
            title: 'Offers',
            href: '/bookmakers/offers',
          },
          {
            title: 'Offers Parameters',
            href: '/bookmakers/offers-parameters',
          },
        ],
      },
      {
        title: 'Others',
        href: '/others',
        icon: MoreOutlinedIcon,
        children: [
          {
            title: 'List',
            href: '/others',
          },
          {
            title: 'Offers',
            href: '/others/offers',
          },
          {
            title: 'Offers Parameters',
            href: '/others/offers-parameters',
          },
        ],
      },
      {
        title: 'Company fields',
        href: '/data',
        icon: SubtitlesIcon,
      },
      {
        title: 'Parameters',
        href: '/parameters',
        icon: DynamicFeedIcon,
      },
      {
        title: 'Products',
        href: '/products',
        icon: LibraryBooksIcon,
      },
      {
        title: 'Product categories',
        href: '/productCategories',
        icon: LibraryBooksIcon,
      },
      {
        title: 'Forms',
        href: '/forms',
        icon: FolderIcon,
        children: [
          {
            title: 'List',
            href: '/forms',
          },
          {
            title: 'Agreements',
            href: '/forms/agreements',
          },
          {
            title: 'Affiliates',
            href: '/forms/affiliates',
          },
        ],
      },
      {
        title: 'Users',
        href: '/users',
        icon: PersonIcon,
      },
    ],
  },
]
