import axios, { AxiosRequestConfig, AxiosError, AxiosPromise } from 'axios'
import { get, omit } from 'lodash'
import { enqueueSnackbar } from '../../components/Notifier/notificationsActions'
import { store } from '../../App'
import { logout } from '../../views/Login/sessionActions'

type MakeRequestType = {
  axiosRequest: () => AxiosPromise
  successText?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  successCallback?: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  errorCallback?: Function
}

const API_URL = process.env.REACT_APP_API_URL

if (!API_URL) {
  throw new Error('API_URL is not defined')
}

const axiosConfig = {
  baseURL: API_URL,
}

const axiosInstance = axios.create(axiosConfig)

axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config }

  //  this config is breaking /images post method so I'm creating an exception of this rule
  if (
    ['post', 'patch'].includes(config.method as string) &&
    config.data &&
    newConfig.url !== '/images'
  ) {
    const exclude = [
      'id',
      'deleted',
      'createdOn',
      'modifiedOn',
      'createdBy',
      'modifiedBy',
      'lastLogin',
    ]

    newConfig.data = omit(config.data, exclude)
  }

  return newConfig
}, Promise.reject)

export const getErrorMessage = (error: AxiosError) =>
  get(error.response, 'data.error.message.message') ||
  get(error.response, '.data.error.message') ||
  get(error.response, 'data.error.message.message') ||
  get(error, 'message') ||
  'Unexpected Error'

export const makeRequest = async ({
  axiosRequest,
  successText,
  successCallback,
  errorCallback,
}: MakeRequestType) => {
  try {
    const response = await axiosRequest()

    if (successText) {
      store.dispatch(
        enqueueSnackbar({
          message: successText,
          options: { variant: 'success' },
        })
      )
    }

    successCallback && successCallback(response)

    return await Promise.resolve(response)
  } catch (error) {
    store.dispatch(
      enqueueSnackbar({
        message: getErrorMessage(error),
        options: { variant: 'error' },
      })
    )

    if (error && error.response && error.response.status === 401) {
      store.dispatch(logout())
    }

    errorCallback && errorCallback(error)

    return Promise.reject(error)
  }
}

export default axiosInstance
