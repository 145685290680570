import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Ranking, RankingFilter } from './types'

export const ADD_FILTER_TO_RANKING = 'ADD_FILTER_TO_RANKING'
export const REMOVE_FILTER_FROM_RANKING = 'REMOVE_FILTER_FROM_RANKING'

export const addFilterToRanking = (
  rankingId: Ranking['id'],
  filter: RankingFilter
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/rankings/${rankingId}/filters`, [
        {
          parameterId: filter.id,
        },
      ]),
    successText: 'Filter added to ranking',
  })

  if (createResponse) {
    dispatch({
      type: ADD_FILTER_TO_RANKING,
      payload: filter,
    })
  }
}

export const removeFilterFromRanking = (
  rankingId: Ranking['id'],
  filterId: RankingFilter['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/rankings/${rankingId}/filters/${filterId}`),
    successText: 'Filter removed ranking',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_FILTER_FROM_RANKING,
      payload: { id: filterId },
    })
  }
}
