import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { BranchParameter, Parameter } from './types'

export const PARAMETERS_GET = 'PARAMETERS_GET'
export const PARAMETER_ADD = 'PARAMETER_ADD'
export const PARAMETER_UPDATE = 'PARAMETER_UPDATE'
export const PARAMETER_REMOVE = 'PARAMETER_REMOVE'
export const PARAMETER_GET = 'PARAMETER_GET'

export const getParameter = (parameterId: Parameter['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(
        `/parameters/${parameterId}?relations=branches,dictionaryParameters`
      ),
  })

  if (getResponse) {
    dispatch({
      type: PARAMETER_GET,
      payload: getResponse.data,
    })
  }
}

// prettier-ignore
export const getAllParameters = (branchId?: BranchParameter['branchId']) => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = branchId ? await makeRequest({
    axiosRequest: () => axios.get(`/parameters?branchId=${branchId}`),
  }) : await makeRequest({
    axiosRequest: () => axios.get('/parameters?relations=branches'),
  })

  if (getResponse) {
    dispatch({
      type: PARAMETERS_GET,
      payload: getResponse.data,
    })
  }
}

export const addParameter = (parameter: Parameter) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/parameters', {
        name: parameter.name,
        slug: parameter.slug,
        prefix: parameter.prefix,
        suffix: parameter.suffix,
        type: parseInt(parameter.type as string, 10),
      }),
    successText: 'Parameter added',
  })

  if (createResponse) {
    dispatch({
      type: PARAMETER_ADD,
      payload: createResponse.data,
    })
  }
}

export const updateParameter = (parameter: Parameter) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/parameters/${parameter.id}`, {
        name: parameter.name,
        prefix: parameter.prefix || '',
        suffix: parameter.suffix || '',
      }),
    successText: 'Parameter updated',
  })

  if (updateResponse) {
    dispatch({
      type: PARAMETER_UPDATE,
      payload: parameter,
    })
  }
}

export const removeParameter = (parameterId: Parameter['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/parameters/${parameterId}`),
    successText: 'Parameter removed',
  })

  if (deleteResponse) {
    dispatch({
      type: PARAMETER_REMOVE,
      payload: parameterId,
    })
  }
}
