import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { OfferAffiliateLinkService } from './types'

export const ADD_OFFER_AFFILIATE_LINK_TO_SERVICE =
  'ADD_OFFER_AFFILIATE_LINK_TO_SERVICE'
export const REMOVE_OFFER_AFFILIATE_LINK_FROM_SERVICE =
  'REMOVE_OFFER_AFFILIATE_LINK_FROM_SERVICE'
export const UPDATE_OFFER_AFFILIATE_LINK_TO_SERVICE =
  'UPDATE_OFFER_AFFILIATE_LINK_TO_SERVICE'

export const addOfferAffiliateLinkServiceToService = (
  offerAffiliateLinkService: OfferAffiliateLinkService,
  offerId: OfferAffiliateLinkService['offerId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/offers/${offerId}/affiliate-link-service`, {
        affiliateLink: offerAffiliateLinkService.affiliateLink,
        serviceId: offerAffiliateLinkService.serviceId,
      }),
    successText: 'OfferAffiliateLinkService added to service',
  })

  if (createResponse) {
    dispatch({
      type: ADD_OFFER_AFFILIATE_LINK_TO_SERVICE,
      payload: {
        ...offerAffiliateLinkService,
        offerId,
      },
    })
  }
}

export const updateOfferAffiliateLinkServiceToService = (
  offerAffiliateLinkService: OfferAffiliateLinkService,
  offerId: OfferAffiliateLinkService['offerId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/offers/${offerId}/affiliate-link-service`, {
        affiliateLink: offerAffiliateLinkService.affiliateLink,
        serviceId: offerAffiliateLinkService.serviceId,
      }),
    successText: 'OfferAffiliateLinkService affiliateLink updated',
  })

  if (updateResponse) {
    dispatch({
      type: UPDATE_OFFER_AFFILIATE_LINK_TO_SERVICE,
      payload: {
        ...offerAffiliateLinkService,
        offerId,
      },
    })
  }
}

export const removeOfferAffiliateLinkServiceFromService = (
  offerId: OfferAffiliateLinkService['offerId'],
  serviceId: OfferAffiliateLinkService['serviceId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/offers/${offerId}/affiliate-link-service/${serviceId}`),
    successText: 'OfferAffiliateLinkService removed from service',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_OFFER_AFFILIATE_LINK_FROM_SERVICE,
      payload: {
        offerId,
        serviceId,
      },
    })
  }
}
