import {
  initialFormState,
  InitialFormStateType,
} from '../../utils/baseReducers'
import { CURRENT_USER_GET } from './currentUserActions'

import { CurrentUserActionsTypes } from './types'

const currentUserReducer = (
  state: InitialFormStateType = initialFormState,
  action: CurrentUserActionsTypes
) => {
  switch (action.type) {
    case CURRENT_USER_GET: {
      return {
        ...state,
        loaded: true,
        isLoading: false,
        data: action.payload,
      }
    }

    default: {
      return state
    }
  }
}

export default currentUserReducer
