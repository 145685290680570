import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { DictionaryData, EntityFormInput } from './types'

export const DICTIONARY_DATA_ADD = 'DICTIONARY_DATA_ADD'
export const DICTIONARY_DATA_UPDATE = 'DICTIONARY_DATA_UPDATE'
export const DICTIONARY_DATA_REMOVE = 'DICTIONARY_DATA_REMOVE'

export const addDictionaryData = (
  entityFormInputId: EntityFormInput['id'],
  dictionaryData: DictionaryData
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/data/${entityFormInputId}/dictionary`, {
        name: dictionaryData.name,
        languageId: dictionaryData.languageId,
      }),
    successText: 'Dictionary Data added',
  })

  if (createResponse) {
    dispatch({
      type: DICTIONARY_DATA_ADD,
      payload: createResponse.data,
    })
  }
}

export const updateDictionaryData = (
  entityFormInputId: EntityFormInput['id'],
  dictionaryData: DictionaryData
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/data/${entityFormInputId}/dictionary`, dictionaryData),
    successText: 'DictionaryData updated',
  })

  if (updateResponse) {
    dispatch({
      type: DICTIONARY_DATA_UPDATE,
      payload: dictionaryData,
    })
  }
}

export const removeDictionaryData = (
  entityFormInputId: EntityFormInput['id'],
  languageId: DictionaryData['languageId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/data/${entityFormInputId}/dictionary/${languageId}`),
    successText: 'DictionaryData removed',
  })

  if (deleteResponse) {
    dispatch({
      type: DICTIONARY_DATA_REMOVE,
      payload: languageId,
    })
  }
}
