import {
  PRODUCT_CATEGORY_GET,
  PRODUCT_CATEGORY_UPDATE,
} from './productCategoryActions'

import {
  getOneFromApi,
  initialFormState,
  updateOneFromApi,
  InitialFormStateType,
} from '../../utils/baseReducers'
import { ProductActionsCategorys } from './types'

const productReducer = (
  state: InitialFormStateType = initialFormState,
  action: ProductActionsCategorys
) => {
  switch (action.type) {
    case PRODUCT_CATEGORY_GET: {
      return getOneFromApi(state, action.payload)
    }

    case PRODUCT_CATEGORY_UPDATE: {
      return updateOneFromApi(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default productReducer
