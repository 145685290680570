import React, { Dispatch } from 'react'
import { withSnackbar } from 'notistack'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'

import StoreTypes from '../../types/StoreType'
import { Notification } from './types'

import { removeSnackbar } from './notificationsActions'

type Props = {
  notifications?: Notification[]
  closeSnackbar: (val?: number) => void
  enqueueSnackbar: (val?: string, val2?: Record<string, unknown>) => void
  removeSnackbar: (val: number) => void
}

class Notifier extends React.Component<Props, Record<string, unknown>> {
  displayed: number[] = []

  componentDidUpdate() {
    const { notifications = [] } = this.props

    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed || this.displayed.includes(key as number)) {
          this.props.closeSnackbar(key)
          return
        }

        this.props.enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (
            event: Record<string, unknown>,
            reason: never,
            closedKey: number
          ) => {
            if (options.onClose) {
              options.onClose(event, reason, closedKey)
            }
          },
          onExited: (_: never, keyToRemove: number) => {
            this.props.removeSnackbar(keyToRemove)
            this.removeDisplayed(keyToRemove)
          },
        })

        this.storeDisplayed(key)
      }
    )
  }

  storeDisplayed = (id?: number) => {
    if (id) {
      this.displayed = [...this.displayed, id]
    }
  }

  removeDisplayed = (id: number) => {
    this.displayed = this.displayed.filter((key) => id !== key)
  }

  render() {
    return null
  }
}

const mapStateToProps = (store: StoreTypes) => ({
  notifications: store.notifications,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  removeSnackbar: (keyToRemove: number) =>
    dispatch(removeSnackbar(keyToRemove)),
})

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Notifier)
)
