import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import { RouteConfig } from 'react-router-config'
import AuthLayout from './layouts/Auth'
import DashboardLayout from './layouts/Dashboard'
import ErrorLayout from './layouts/Error'
import AuthenticateProvider from './providers/AuthenticateProvider'
import OverviewView from './views/Overview'
import SitesStatus from './views/SitesStatus'

export default [
  {
    path: '/',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/overview" />,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('./views/Login')),
      },
      {
        // eslint-disable-next-line react/display-name,react/no-multi-comp
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('./views/Errors/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('./views/Errors/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('./views/Errors/Error500')),
      },
      {
        // eslint-disable-next-line react/display-name,react/no-multi-comp
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    route: '*',
    component: AuthenticateProvider(DashboardLayout),
    routes: [
      {
        path: '/overview',
        exact: true,
        component: OverviewView,
      },
      {
        path: '/sites-status',
        exact: true,
        component: SitesStatus,
      },
      {
        path: '/users',
        exact: true,
        component: lazy(() => import('./views/Users')),
      },
      {
        path: '/users/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Users/Edit')),
      },
      {
        path: '/users/add',
        exact: true,
        component: lazy(() => import('./views/Users/Add')),
      },
      {
        path: '/lenders',
        exact: true,
        component: lazy(() => import('./views/Lenders')),
      },
      {
        path: '/lenders/add',
        exact: true,
        component: lazy(() => import('./views/Lenders/Add')),
      },
      {
        path: '/lenders/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Lenders/Edit')),
      },
      {
        path: '/lenders/offers',
        exact: true,
        component: lazy(() => import('./views/LenderOffers')),
      },
      {
        path: '/lenders/offers/add',
        exact: true,
        component: lazy(() => import('./views/LenderOffers/Add')),
      },
      {
        path: '/lenders/offers/edit/:id',
        exact: true,
        component: lazy(() => import('./views/LenderOffers/Edit')),
      },
      {
        path: '/lenders/offers-parameters',
        exact: true,
        component: lazy(() => import('./views/LendersOffersParameters')),
      },
      {
        path: '/lenders/debtors-bases/edit/:id',
        exact: true,
        component: lazy(() => import('./views/DebtorsBases/Edit')),
      },
      {
        path: '/lenders/debtors-bases',
        exact: true,
        component: lazy(() => import('./views/DebtorsBases')),
      },
      {
        path: '/branches',
        exact: true,
        component: lazy(() => import('./views/Branches')),
      },
      {
        path: '/banks',
        exact: true,
        component: lazy(() => import('./views/Banks')),
      },
      {
        path: '/banks/add',
        exact: true,
        component: lazy(() => import('./views/Banks/Add')),
      },
      {
        path: '/banks/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Banks/Edit')),
      },
      {
        path: '/banks/offers',
        exact: true,
        component: lazy(() => import('./views/BankOffers')),
      },
      {
        path: '/banks/offers/add',
        exact: true,
        component: lazy(() => import('./views/BankOffers/Add')),
      },
      {
        path: '/banks/offers/edit/:id',
        exact: true,
        component: lazy(() => import('./views/BankOffers/Edit')),
      },
      {
        path: '/banks/offers-parameters',
        exact: true,
        component: lazy(() => import('./views/BanksOffersParameters')),
      },
      {
        path: '/data',
        exact: true,
        component: lazy(() => import('./views/EntityFormInputs')),
      },
      {
        path: '/data/add',
        exact: true,
        component: lazy(() => import('./views/EntityFormInputs/Add')),
      },
      {
        path: '/data/edit/:id',
        exact: true,
        component: lazy(() => import('./views/EntityFormInputs/Edit')),
      },
      {
        path: '/parameters',
        exact: true,
        component: lazy(() => import('./views/Parameters')),
      },
      {
        path: '/parameters/add',
        exact: true,
        component: lazy(() => import('./views/Parameters/Add')),
      },
      {
        path: '/parameters/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Parameters/Edit')),
      },
      {
        path: '/productCategories',
        exact: true,
        component: lazy(() => import('./views/ProductCategories')),
      },
      {
        path: '/products',
        exact: true,
        component: lazy(() => import('./views/Products')),
      },
      {
        path: '/products/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Products/Edit')),
      },
      {
        path: '/rankings/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Rankings/Edit')),
      },
      {
        path: '/rankings',
        exact: true,
        component: lazy(() => import('./views/Rankings')),
      },
      {
        path: '/rankings/edit-all',
        exact: true,
        component: lazy(() => import('./views/Rankings/EditAll')),
      },
      {
        path: '/vpns',
        exact: true,
        component: lazy(() => import('./views/Vpns')),
      },
      {
        path: '/vpns/add',
        exact: true,
        component: lazy(() => import('./views/Vpns/Add')),
      },
      {
        path: '/vpns/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Vpns/Edit')),
      },
      {
        path: '/vpns/offers',
        exact: true,
        component: lazy(() => import('./views/VpnOffers')),
      },
      {
        path: '/vpns/offers/add',
        exact: true,
        component: lazy(() => import('./views/VpnOffers/Add')),
      },
      {
        path: '/vpns/offers/edit/:id',
        exact: true,
        component: lazy(() => import('./views/VpnOffers/Edit')),
      },
      {
        path: '/vpns/offers-parameters',
        exact: true,
        component: lazy(() => import('./views/VpnsOffersParameters')),
      },
      {
        path: '/bookmakers',
        exact: true,
        component: lazy(() => import('./views/Bookmakers')),
      },
      {
        path: '/bookmakers/add',
        exact: true,
        component: lazy(() => import('./views/Bookmakers/Add')),
      },
      {
        path: '/bookmakers/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Bookmakers/Edit')),
      },
      {
        path: '/bookmakers/offers',
        exact: true,
        component: lazy(() => import('./views/BookmakerOffers')),
      },
      {
        path: '/bookmakers/offers/add',
        exact: true,
        component: lazy(() => import('./views/BookmakerOffers/Add')),
      },
      {
        path: '/bookmakers/offers/edit/:id',
        exact: true,
        component: lazy(() => import('./views/BookmakerOffers/Edit')),
      },
      {
        path: '/bookmakers/offers-parameters',
        exact: true,
        component: lazy(() => import('./views/BookmakersOffersParameters')),
      },
      {
        path: '/others',
        exact: true,
        component: lazy(() => import('./views/Others')),
      },
      {
        path: '/others/add',
        exact: true,
        component: lazy(() => import('./views/Others/Add')),
      },
      {
        path: '/others/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Others/Edit')),
      },
      {
        path: '/others/offers',
        exact: true,
        component: lazy(() => import('./views/OtherOffers')),
      },
      {
        path: '/others/offers/add',
        exact: true,
        component: lazy(() => import('./views/OtherOffers/Add')),
      },
      {
        path: '/others/offers/edit/:id',
        exact: true,
        component: lazy(() => import('./views/OtherOffers/Edit')),
      },
      {
        path: '/others/offers-parameters',
        exact: true,
        component: lazy(() => import('./views/OthersOffersParameters')),
      },
      {
        path: '/forms',
        exact: true,
        component: lazy(() => import('./views/Forms')),
      },
      {
        path: '/forms/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Forms/Edit')),
      },
      {
        path: '/forms/agreements',
        exact: true,
        component: lazy(() => import('./views/Agreements')),
      },
      {
        path: '/forms/agreements/add',
        exact: true,
        component: lazy(() => import('./views/Agreements/Add')),
      },
      {
        path: '/forms/agreements/edit/:id',
        exact: true,
        component: lazy(() => import('./views/Agreements/Edit')),
      },
      {
        path: '/forms/affiliates',
        exact: true,
        component: lazy(() => import('./views/Affiliates')),
      },
      {
        // eslint-disable-next-line react/display-name,react/no-multi-comp
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
] as RouteConfig[]
