import {
  getOneFromApi,
  initialFormState,
  InitialFormStateType,
  updateOneFromApi,
} from '../../utils/baseReducers'
import { AGREEMENT_GET, AGREEMENT_UPDATE } from './agreementActions'

import { AgreementActionsTypes } from './types'

const agreementReducer = (
  state: InitialFormStateType = initialFormState,
  action: AgreementActionsTypes
) => {
  switch (action.type) {
    case AGREEMENT_GET: {
      return getOneFromApi(state, action.payload)
    }

    case AGREEMENT_UPDATE: {
      return updateOneFromApi(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default agreementReducer
