import {
  getAll,
  initialState,
  InitialStateType,
} from '../../utils/baseReducers'
import { SERVICES_GET } from './servicesActions'
import { ServicesActionsTypes } from './types'

const servicesReducer = (
  state: InitialStateType = initialState,
  action: ServicesActionsTypes
) => {
  switch (action.type) {
    case SERVICES_GET: {
      return getAll(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default servicesReducer
