export const offersTypeList = [
  {
    id: 1,
    name: 'chwilówka',
  },
  {
    id: 2,
    name: 'ratalka',
  },
  {
    id: 3,
    name: 'pożyczka dla firm',
  },
]

export const offersType = offersTypeList.reduce((acc, item) => {
  acc[item.id] = item.name

  return acc
}, {} as { [key: string]: string })

export const NUMBER_TYPE_ID = 1
export const STRING_TYPE_ID = 2
export const RANGE_TYPE_ID = 3
export const BOOLEAN_TYPE_ID = 4
export const ARRAY_TYPE_ID = 5
export const RANG_DATE_TYPE_ID = 6

export const parametersType: { [key: string]: string } = {
  [NUMBER_TYPE_ID]: 'number',
  [STRING_TYPE_ID]: 'string',
  [RANGE_TYPE_ID]: 'range',
  [BOOLEAN_TYPE_ID]: 'boolean',
  [ARRAY_TYPE_ID]: 'multiValue',
  [RANG_DATE_TYPE_ID]: 'rangeDate',
}

export const RANG_DATE_TYPE_DAYS_ID = 1
export const RANG_DATE_TYPE_WEEKS_ID = 2
export const RANG_DATE_TYPE_MONTHS_ID = 3
export const RANG_DATE_TYPE_YEARS_ID = 4

export const parameterRangeDateType: { [key: string]: string } = {
  [RANG_DATE_TYPE_DAYS_ID]: 'days',
  [RANG_DATE_TYPE_WEEKS_ID]: 'weeks',
  [RANG_DATE_TYPE_MONTHS_ID]: 'months',
  [RANG_DATE_TYPE_YEARS_ID]: 'years',
}

export const parameterRangeDateTypeNumbers: { [key: string]: number } = {
  [RANG_DATE_TYPE_DAYS_ID]: 1,
  [RANG_DATE_TYPE_WEEKS_ID]: 7,
  [RANG_DATE_TYPE_MONTHS_ID]: 30,
  [RANG_DATE_TYPE_YEARS_ID]: 365,
}

export const createRatingsList = (max = 5, step = 0.5) =>
  Array(max / step + 1)
    .fill(0)
    .map((_, index) => {
      const currentVal = index * step
      return {
        value: currentVal,
        name: currentVal.toString().includes('.')
          ? currentVal
          : `${currentVal}.0`,
      }
    })

export const PARM_BOOL_NO_ID = '0'
export const PARM_BOOL_YES_ID = '1'

export const paramBooleanOptionsList = [
  {
    value: PARM_BOOL_NO_ID,
    name: 'No',
  },
  {
    value: PARM_BOOL_YES_ID,
    name: 'Yes',
  },
]

export const paramBooleanOptions = paramBooleanOptionsList.reduce(
  (acc, item) => {
    acc[item.value] = item.name

    return acc
  },
  {} as { [key: string]: string }
)

export const predefinedBranchesId = {
  lenders: 2,
  banks: 1,
  vpns: 3,
  bookmakers: 4,
  others: 5,
}

export const BRANCH_BANKS_ID = 1
export const BRANCH_LENDERS_ID = 2
export const BRANCH_VPNS_ID = 3
export const BRANCH_BOOKMAKERS_ID = 4
export const BRANCH_OTHERS_ID = 5

export const predefinedBranchesNames: { [key: number]: string } = {
  [BRANCH_BANKS_ID]: 'banks',
  [BRANCH_LENDERS_ID]: 'lenders',
  [BRANCH_VPNS_ID]: 'vpns',
  [BRANCH_BOOKMAKERS_ID]: 'bookmakers',
  [BRANCH_OTHERS_ID]: 'others',
}
