import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { dispatchResetReducer } from '../../utils/baseReducers'
import { Offer, OfferCountingCosts } from './types'

export const OFFER_COUNTING_COSTS_CREATE = 'OFFER_COUNTING_COSTS_CREATE'
export const OFFER_COUNTING_COSTS_UPDATE = 'OFFER_COUNTING_COSTS_UPDATE'
export const OFFERS_COUNTING_COSTS_GET = 'OFFERS_COUNTING_COSTS_GET'
export const RESET_OFFERS_COUNTING_COSTS = 'RESET_OFFERS_COUNTING_COSTS'

export const getOffersCountingCosts = (offerId: Offer['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatchResetReducer(dispatch, RESET_OFFERS_COUNTING_COSTS)
  const response = await makeRequest({
    axiosRequest: () =>
      axios.get(`/offers/${offerId}/?relations=offersCountingCosts`),
  })

  if (response && response.data.offersCountingCosts) {
    dispatch({
      type: OFFERS_COUNTING_COSTS_GET,
      payload: response.data.offersCountingCosts,
    })
  }
}

export const createOfferCountingCosts = (
  offerCountingCosts: OfferCountingCosts
) => async (dispatch: Dispatch<AnyAction>) => {
  const response = await makeRequest({
    axiosRequest: () =>
      axios.post(`/offers/${offerCountingCosts.offerId}/counting-costs`, {
        countingCostsTypeId: offerCountingCosts.countingCostsTypeId,
        interestRate: offerCountingCosts.interestRate,
      }),
    successText: 'Counting Costs created',
  })

  if (response) {
    dispatch({
      type: OFFER_COUNTING_COSTS_CREATE,
      payload: response.data,
    })
  }
}

export const updateOfferCountingCosts = (
  offerCountingCosts: OfferCountingCosts
) => async (dispatch: Dispatch<AnyAction>) => {
  // eslint-disable-next-line
  let data = {
    countingCostsTypeId: offerCountingCosts.countingCostsTypeId,
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  } as any

  if (offerCountingCosts.provision !== undefined) {
    data = {
      ...data,
      provision: offerCountingCosts.provision.toString(),
    }
  }

  if (offerCountingCosts.interestRate !== undefined) {
    data = {
      ...data,
      interestRate: offerCountingCosts.interestRate.toString(),
    }
  }

  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/offers/${offerCountingCosts.offerId}/counting-costs`, data),
    successText: 'Counting Costs updated',
  })

  if (updateResponse) {
    dispatch({
      type: OFFER_COUNTING_COSTS_UPDATE,
      payload: offerCountingCosts,
    })
  }
}
