import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'

export const CURRENT_USER_GET = 'CURRENT_USER_GET'

export const getCurrentUser = () => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () => axios.get('/users/me'),
  })

  if (createResponse) {
    dispatch({
      type: CURRENT_USER_GET,
      payload: createResponse.data,
    })
  }
}
