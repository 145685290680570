import { Product, ProductTabType } from './types'
import { Dispatch, AnyAction } from 'redux'
import { isNull, omitBy } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'

export const PRODUCT_TAB_ADD = 'PRODUCT_TAB_ADD'
export const PRODUCT_TAB_UPDATE = 'PRODUCT_TAB_UPDATE'
export const PRODUCT_TAB_REMOVE = 'PRODUCT_TAB_REMOVE'

export const addProductTab = (
  productId: Product['id'],
  productTab: ProductTabType,
  latestItemUpdatedIndex: number
) => {
  const productTabData = omitBy(productTab, isNull)

  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () => axios.post('/tabs', { ...productTabData, productId }),
      successText: 'Product Tab added',
    })

    if (createResponse) {
      dispatch({
        type: PRODUCT_TAB_ADD,
        payload: {
          ...createResponse.data,
          tabsParameters: [],
          latestItemUpdatedIndex,
        },
      })
    }
  }
}

export const updateProductTab = (
  productId: Product['id'],
  productTab: ProductTabType,
  latestItemUpdatedIndex: number
) => {
  const productTabData = omitBy(productTab, isNull)

  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(`/tabs/${productTabData.id}`, {
          ...productTabData,
          productId,
        }),
      successText: 'Product Tab udpated',
    })

    if (createResponse) {
      dispatch({
        type: PRODUCT_TAB_UPDATE,
        payload: {
          ...createResponse.data,
          ...productTabData,
          latestItemUpdatedIndex,
        },
      })
    }
  }
}

export const removeProduct = (
  productTab: ProductTabType,
  latestItemUpdatedIndex: number
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/tabs/${productTab.id}`),
    successText: 'Product Tab removed',
  })

  if (deleteResponse) {
    dispatch({
      type: PRODUCT_TAB_REMOVE,
      payload: { ...productTab, latestItemUpdatedIndex },
    })
  }
}
