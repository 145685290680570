import { DEBTOR_GET, DEBTOR_UPDATE, DEBTOR_LOGO_UPDATE } from './debtorActions'
import {
  getOneFromApi,
  initialFormState,
  updateOneFromApi,
  InitialFormStateType,
} from '../../utils/baseReducers'

import { DebtorActionsTypes } from './types'

const debtorReducer = (
  state: InitialFormStateType = initialFormState,
  action: DebtorActionsTypes
) => {
  switch (action.type) {
    case DEBTOR_GET: {
      return getOneFromApi(state, action.payload)
    }

    case DEBTOR_UPDATE: {
      return updateOneFromApi(state, action.payload)
    }

    case DEBTOR_LOGO_UPDATE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
        loaded: true,
      }
    }

    default: {
      return state
    }
  }
}

export default debtorReducer
