import { USER_GET, USER_UPDATE } from './userActions'
import {
  getOneFromApi,
  initialFormState,
  updateOneFromApi,
  InitialFormStateType,
} from '../../utils/baseReducers'

import { UserActionsTypes } from './types'

const userReducer = (
  state: InitialFormStateType = initialFormState,
  action: UserActionsTypes
) => {
  switch (action.type) {
    case USER_GET: {
      return getOneFromApi(state, action.payload)
    }

    case USER_UPDATE: {
      return updateOneFromApi(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default userReducer
