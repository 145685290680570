import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Agreement } from './types'

export const AGREEMENTS_GET = 'AGREEMENTS_GET'
export const AGREEMENTS_ADD = 'AGREEMENTS_ADD'

export const getAllAgreements = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/agreements'),
  })

  if (getResponse) {
    dispatch({
      type: AGREEMENTS_GET,
      payload: getResponse.data,
    })
  }
}

export const addAgreement = (agreement: Agreement) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/agreements', {
        content: agreement.content,
        required: agreement.required,
        parentId: parseInt(agreement.parentId as string),
        isOnlyInfo: agreement.isOnlyInfo,
      }),
    successText: 'Agreement added',
  })

  if (createResponse) {
    dispatch({
      type: AGREEMENTS_ADD,
      payload: createResponse.data,
    })
  }
}
