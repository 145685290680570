import { AnyAction, Dispatch } from 'redux'
import { store } from '../../App'
import { enqueueSnackbar } from '../../components/Notifier/notificationsActions'
import axios, { makeRequest } from '../../utils/axios'
import { Branch } from '../Branches/types'
import { BranchParameter, Parameter } from './types'

export const ADD_BRANCH_TO_PARAMETER = 'ADD_BRANCH_TO_PARAMETER'
export const REMOVE_BRANCH_FROM_PARAMETER = 'REMOVE_BRANCH_FROM_PARAMETER'

export const addBranchToParameter = (
  parameterId: Parameter['id'],
  branch: Branch
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/parameters/${parameterId}/branches`, {
        branchId: branch.id,
      }),
    successText: 'Branch added to Parameter',
  })

  if (createResponse) {
    dispatch({
      type: ADD_BRANCH_TO_PARAMETER,
      payload: {
        parameterId,
        branch,
      },
    })
  }
}

export const removeBranchFromParameter = (
  parameterId: Parameter['id'],
  branchId: BranchParameter['branchId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/parameters/${parameterId}/branches/${branchId}`),
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    successCallback: (response: any) => {
      if (response.data === true) {
        store.dispatch(
          enqueueSnackbar({
            message: 'Branch removed from Parameter',
            options: { variant: 'success' },
          })
        )
      }

      if (response.data === false) {
        store.dispatch(
          enqueueSnackbar({
            message:
              'Parameter is used in offers - remove it from them to unpin branches',
            options: { variant: 'error' },
          })
        )
      }
    },
  })

  if (deleteResponse.data === true) {
    dispatch({
      type: REMOVE_BRANCH_FROM_PARAMETER,
      payload: {
        parameterId,
        branchId,
      },
    })
  }
}
