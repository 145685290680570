import { getAll, initialState, InitialStateType, update, add } from '../../utils/baseReducers'
import { UPDATE_OFFER_COUNTING_COSTS_VALUE } from './offerCountingCostsValuesActions'
import { OFFER_COUNTING_COSTS_UPDATE, OFFER_COUNTING_COSTS_CREATE, OFFERS_COUNTING_COSTS_GET, RESET_OFFERS_COUNTING_COSTS } from './offersCountingCostsActions'
import { OfferCountingCostsValue, OffersCountingCostsActionsTypes } from './types'

const offersCountingCostsReducer = (
  state: InitialStateType = initialState,
  action: OffersCountingCostsActionsTypes,
) => {
  switch (action.type) {
  case OFFERS_COUNTING_COSTS_GET: {
    return getAll(state, action.payload)
  }

  case OFFER_COUNTING_COSTS_CREATE: {
    return add(state, action.payload)
  }

  case OFFER_COUNTING_COSTS_UPDATE: {
    return {
      ...state,
      data: state.data.map(
        (offerCountingCosts) =>
          offerCountingCosts.countingCostsTypeId === action.payload.countingCostsTypeId ?
            {
              ...offerCountingCosts,
              interestRate: action.payload.interestRate ?? offerCountingCosts.interestRate,
              provision: action.payload.provision ?? offerCountingCosts.provision,
            }
            : offerCountingCosts,
      )
    }
  }

  case UPDATE_OFFER_COUNTING_COSTS_VALUE: {
    return {
      ...state,
      data: state.data.map(
        (offerCountingCosts) =>
          offerCountingCosts.id === action.payload.countingCostsId ?
            {
              ...offerCountingCosts,
              offerCountingCostsValues: offerCountingCosts.offerCountingCostsValues !== undefined && [
                ...offerCountingCosts.offerCountingCostsValues.filter((offerCountingCostsValue: any) =>
                  !action.payload.offerCountingCostsValues.find((newOfferCountingCostsValue: any) =>
                    offerCountingCostsValue.amount === newOfferCountingCostsValue.amount &&
                    offerCountingCostsValue.period === newOfferCountingCostsValue.period,
                  )),
                ...action.payload.offerCountingCostsValues,
              ] || action.payload.offerCountingCostsValues,
            }
            : offerCountingCosts,
      ),
    }
  }

  case RESET_OFFERS_COUNTING_COSTS: {
    return initialState
  }

  default: {
    return state
  }
  }
}

export default offersCountingCostsReducer
