import { List } from '@material-ui/core'
import React from 'react'
import { match } from 'react-router'
import { matchPath, RouteComponentProps } from 'react-router-dom'
import NavigationListItem from './NavigationListItem'

import { Page } from './types'

type Props = {
  pages: Array<Page>
  depth: number
  router: RouteComponentProps
}

const reduceChildRoutes = ({ pages, ...rest }: Props) =>
  pages.reduce((acc, page) => {
    if (page.children) {
      const open: match<{ [x: string]: string | undefined }> | null = matchPath(
        rest.router.location.pathname,
        {
          path: page.href,
          exact: false,
        }
      )

      acc.push(
        <NavigationListItem
          depth={rest.depth}
          icon={page.icon}
          key={page.title}
          open={Boolean(open)}
          title={page.title}
        >
          <NavigationList
            depth={rest.depth + 1}
            pages={page.children}
            router={rest.router}
          />
        </NavigationListItem>
      )
    }

    if (!page.children) {
      acc.push(
        <NavigationListItem
          depth={rest.depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          title={page.title}
        />
      )
    }

    return acc
  }, [] as React.ReactNode[])

const NavigationList: React.SFC<Props> = ({ pages, ...rest }) => (
  <List>{reduceChildRoutes({ pages, ...rest })}</List>
)

export default NavigationList
