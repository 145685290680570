import { Dispatch, AnyAction } from 'redux'
import { isNull, omit, omitBy } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'
import { ProductCategory } from './types'

export const PRODUCT_CATEGORY_GET = 'PRODUCT_CATEGORY_GET'
export const PRODUCT_CATEGORY_UPDATE = 'PRODUCT_CATEGORY_UPDATE'

export const getProductCategory = (
  productCategoryId: ProductCategory['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(`/products-categories/${productCategoryId}?relations=products`),
  })

  if (getResponse) {
    dispatch({
      type: PRODUCT_CATEGORY_GET,
      payload: getResponse.data,
    })
  }
}

export const updateProductCategory = (
  productCategoryId: ProductCategory['id'],
  productCategory: ProductCategory
) => {
  const productCategoryData = omit(omitBy(productCategory, isNull), [
    'productParameters',
    'productDebtorsBases',
    'tableData',
    'productsTypes',
  ])

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(
          `/products-categories/${productCategoryId}`,
          productCategoryData
        ),
      successText: 'Product Category updated',
    })

    if (updateResponse) {
      dispatch({
        type: PRODUCT_CATEGORY_UPDATE,
        payload: productCategory,
      })
    }
  }
}
