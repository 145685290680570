import { get } from 'lodash'

import {
  getOneFromApi,
  initialFormState,
  InitialFormStateType,
  updateOneFromApi,
} from '../../utils/baseReducers'
import {
  DICTIONARY_TAB_ADD,
  DICTIONARY_TAB_REMOVE,
  DICTIONARY_TAB_UPDATE,
} from './dictionaryTabsActions'
import { PRODUCT_GET, PRODUCT_UPDATE } from './productActions'
import {
  PRODUCT_TAB_PARAMETER_REMOVE,
  PRODUCT_TAB_PARAMETERS_ADD,
} from './productTabParamasActions'
import {
  PRODUCT_TAB_ADD,
  PRODUCT_TAB_REMOVE,
  PRODUCT_TAB_UPDATE,
} from './productTabsActions'
import { ProductActions, ProductTabType } from './types'

const productReducer = (
  state: InitialFormStateType = initialFormState,
  action: ProductActions
) => {
  switch (action.type) {
    case PRODUCT_GET: {
      return getOneFromApi(state, action.payload)
    }

    case PRODUCT_UPDATE: {
      return updateOneFromApi(state, action.payload)
    }

    case PRODUCT_TAB_ADD: {
      const tabs = get(state.data, 'tabs', [])
      return {
        ...state,

        data: {
          ...state.data,
          latestItemUpdatedIndex: action.payload.latestItemUpdatedIndex,
          tabs: [...tabs, action.payload],
        },
      }
    }

    case PRODUCT_TAB_UPDATE: {
      const tabs = get(state.data, 'tabs', [])
      return {
        ...state,

        data: {
          ...state.data,
          latestItemUpdatedIndex: action.payload.latestItemUpdatedIndex,
          tabs: tabs.map((tab: ProductTabType) => {
            if (tab.id === action.payload.id) {
              return action.payload
            }

            return tab
          }),
        },
      }
    }

    case PRODUCT_TAB_REMOVE: {
      const tabs = get(state.data, 'tabs', [])
      return {
        ...state,

        data: {
          ...state.data,
          latestItemUpdatedIndex: action.payload.latestItemUpdatedIndex,
          tabs: tabs.filter(
            (tab: ProductTabType) => tab.id !== action.payload.id
          ),
        },
      }
    }

    case DICTIONARY_TAB_ADD: {
      const tabs = get(state.data, 'tabs', [])

      return {
        ...state,
        data: {
          ...state.data,
          tabs: tabs.map((tab: ProductTabType) => {
            if (tab.id === action.payload.tabId && tab.dictionaryTabs) {
              tab.dictionaryTabs.push({ ...action.payload })
            }

            return tab
          }),
        },
      }
    }

    case DICTIONARY_TAB_REMOVE: {
      const tabs = get(state.data, 'tabs', [])

      return {
        ...state,
        data: {
          ...state.data,
          tabs: tabs.map((tab: ProductTabType) => {
            if (tab.id === action.payload.tabId) {
              const updatedTab = {
                ...tab,
                dictionaryTabs:
                  (tab.dictionaryTabs &&
                    tab.dictionaryTabs.filter(
                      (dictionaryTab) =>
                        dictionaryTab.languageId !== action.payload.languageId
                    )) ||
                  [],
              }
              return updatedTab
            }

            return tab
          }),
        },
      }
    }

    case DICTIONARY_TAB_UPDATE: {
      const tabs = get(state.data, 'tabs', [])

      return {
        ...state,
        data: {
          ...state.data,
          tabs: tabs.map((tab: ProductTabType) => {
            if (tab.id === action.payload.tabId) {
              return {
                ...tab,
                dictionaryTabs:
                  (tab.dictionaryTabs &&
                    tab.dictionaryTabs.map((dictionaryTab) => {
                      if (
                        dictionaryTab.languageId === action.payload.languageId
                      ) {
                        return action.payload
                      }

                      return dictionaryTab
                    })) ||
                  [],
              }
            }

            return tab
          }),
        },
      }
    }

    case PRODUCT_TAB_PARAMETERS_ADD: {
      const tabs = get(state.data, 'tabs', [])
      return {
        ...state,

        data: {
          ...state.data,
          latestItemUpdatedIndex: action.payload.latestItemUpdatedIndex,
          tabs: tabs.map((tab: ProductTabType) => {
            if (tab.id === action.payload.productTabId) {
              const updatedTab = {
                ...tab,
                tabsParameters: [
                  ...tab.tabsParameters,
                  ...action.payload.parameters,
                ],
              }
              return updatedTab
            }

            return tab
          }),
        },
      }
    }

    case PRODUCT_TAB_PARAMETER_REMOVE: {
      const tabs = get(state.data, 'tabs', [])
      return {
        ...state,

        data: {
          ...state.data,
          latestItemUpdatedIndex: action.payload.latestItemUpdatedIndex,
          tabs: tabs.map((tab: ProductTabType) => {
            if (tab.id === action.payload.productTabId) {
              const updatedTab = {
                ...tab,
                tabsParameters: tab.tabsParameters.filter(
                  (param) => param.id !== action.payload.parameter.id
                ),
              }
              return updatedTab
            }

            return tab
          }),
        },
      }
    }

    default: {
      return state
    }
  }
}

export default productReducer
