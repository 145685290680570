import { Affiliate } from './types'
import { Dispatch, AnyAction } from 'redux'
import { isNull, omit, omitBy } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'

export const AFFILIATE_ADD = 'AFFILIATE_ADD'
export const AFFILIATES_GET = 'AFFILIATES_GET'
export const AFFILIATE_UPDATE = 'AFFILIATE_UPDATE'
export const AFFILIATE_REMOVE = 'AFFILIATE_REMOVE'

export const getAllAffiliates = () => async (dispatch: Dispatch<AnyAction>) => {
  const response = await makeRequest({
    axiosRequest: () => axios.get('/affiliates'),
  })

  if (response) {
    dispatch({
      type: AFFILIATES_GET,
      payload: response.data,
    })
  }
}

export const addAffiliate = (affiliate: Affiliate) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () => axios.post('/affiliates', affiliate),
      successText: 'Affiliate added',
    })

    if (createResponse) {
      dispatch({
        type: AFFILIATE_ADD,
        payload: createResponse.data,
      })
    }
  }
}

export const removeAffiliate = (affiliateId: Affiliate['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/affiliates/${affiliateId}`),
    successText: 'Affiliate removed',
  })

  if (deleteResponse) {
    dispatch({
      type: AFFILIATE_REMOVE,
      payload: affiliateId,
    })
  }
}

export const updateAffiliate = (
  affiliateId: Affiliate['id'],
  affiliate: Affiliate
) => {
  const affiliateData = omit(omitBy(affiliate, isNull))

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(`/affiliates/${affiliateId}`, affiliateData),
      successText: 'Affiliate updated',
    })

    if (updateResponse) {
      dispatch({
        type: AFFILIATE_UPDATE,
        payload: affiliate,
      })
    }
  }
}
