import { clearAuthTokens, setAuthTokens, getAccessToken } from 'axios-jwt'
import jwt from 'jsonwebtoken'

import { authResponseToAuthTokens } from '../../utils/axios/jwtInterceptor'
import { Dispatch, AnyAction } from 'redux'
import axios, { makeRequest } from '../../utils/axios'

export const SESSION_LOGIN = 'SESSION_LOGIN'
export const SESSION_LOGOUT = 'SESSION_LOGOUT'

export const login = (username: string, password: string) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const loginResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/auth/login', {
        username,
        password,
      }),
  })

  setAuthTokens(authResponseToAuthTokens(loginResponse.data))

  if (loginResponse) {
    dispatch({
      type: SESSION_LOGIN,
      payload: jwt.decode(loginResponse.data.token),
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout: any = () => async (dispatch: Dispatch<AnyAction>) => {
  const token = getAccessToken()
  clearAuthTokens()
  dispatch({
    type: SESSION_LOGOUT,
  })

  await makeRequest({
    axiosRequest: () =>
      axios.post('/auth/logout', {
        token,
      }),
  })
}
