import { ProductCategory } from './types'
import { Dispatch, AnyAction } from 'redux'
import { isNull, omit, omitBy } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'

export const PRODUCT_CATEGORY_ADD = 'PRODUCT_CATEGORY_ADD'
export const PRODUCT_CATEGORIES_GET = 'PRODUCT_CATEGORIES_GET'
export const PRODUCT_CATEGORY_REMOVE = 'PRODUCT_CATEGORY_REMOVE'
export const PRODUCT_CATEGORY_UPDATE = 'PRODUCT_CATEGORY_UPDATE'

export const getAllProductCategories = (withRelations?: boolean) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const productsUrl =
    (withRelations && '/products-categories?relations=products') ||
    '/products-categories'
  const response = await makeRequest({
    axiosRequest: () => axios.get(productsUrl),
  })

  if (response) {
    dispatch({
      type: PRODUCT_CATEGORIES_GET,
      payload: response.data,
    })
  }
}

export const addProductCategory = (product: ProductCategory) => {
  const productData = omitBy(product, isNull)

  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () => axios.post('/products-categories', productData),
      successText: 'Product Category added',
    })

    if (createResponse) {
      dispatch({
        type: PRODUCT_CATEGORY_ADD,
        payload: createResponse.data,
      })
    }
  }
}

export const removeProductCategory = (
  productId: ProductCategory['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/products-categories/${productId}`),
    successText: 'Product Category removed',
  })

  if (deleteResponse) {
    dispatch({
      type: PRODUCT_CATEGORY_REMOVE,
      payload: productId,
    })
  }
}

export const updateProductCategory = (
  productCategoryId: ProductCategory['id'],
  productCategory: ProductCategory
) => {
  const productCategoryData = omit(omitBy(productCategory, isNull), [
    'productParameters',
    'productDebtorsBases',
    'tableData',
    'productsTypes',
  ])

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(
          `/products-categories/${productCategoryId}`,
          productCategoryData
        ),
      successText: 'Product Category updated',
    })

    if (updateResponse) {
      dispatch({
        type: PRODUCT_CATEGORY_UPDATE,
        payload: productCategory,
      })
    }
  }
}
