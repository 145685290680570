import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Entity, EntityFormValues, MappedEntityFormValues } from './types'
import StoreType from '../../types/StoreType'
export const ENTITY_GET = 'ENTITY_GET'
export const ENTITY_UPDATE = 'ENTITY_UPDATE'
export const ENTITY_LOGO_UPDATE = 'ENTITY_LOGO_UPDATE'
export const RESET_ENTITY = 'RESET_ENTITY'

export const getEntity = (entityId: Entity['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/entities/${entityId}?relations=data`),
  })
  if (getResponse) {
    dispatch({
      type: ENTITY_GET,
      payload: getResponse.data,
    })
  }
}

export const updateEntity = (
  entityId: Entity['id'],
  entity: MappedEntityFormValues
) => {
  const { name, ...enitnyDataValues } = entity
  let updatedListForStore = [
    {
      ...entity.name,
      slug: 'name',
      name: 'Name',
    },
  ]

  return async (dispatch: Dispatch<AnyAction>, getState: () => StoreType) => {
    const state = getState()
    const prevEntityData = state.entity.data.entitiesData

    const { dataToUpdate, dataToCreate, dataToDelete } = Object.keys(
      enitnyDataValues
    ).reduce(
      (acc, key: string) => {
        const relatedItem: EntityFormValues | undefined = prevEntityData.find(
          (item: EntityFormValues) => item.slug === key
        )

        if (relatedItem && entity[key].value === '') {
          acc.dataToDelete.push(entity[key])
        } else if (
          relatedItem &&
          entity[key].value &&
          entity[key].value !== '' &&
          relatedItem.value !== entity[key].value
        ) {
          acc.dataToUpdate.push(entity[key])
        } else if (
          !relatedItem &&
          entity[key].value &&
          entity[key].value !== ''
        ) {
          acc.dataToCreate.push(entity[key])
        } else {
          updatedListForStore.push(entity[key])
        }

        return acc
      },
      {
        dataToUpdate: [] as EntityFormValues[],
        dataToCreate: [] as EntityFormValues[],
        dataToDelete: [] as EntityFormValues[],
      }
    )

    const updateResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(`/entities/${entityId}`, {
          name: name.value,
        }),
      successText: 'Entity updated',
    })

    if (dataToCreate.length > 0) {
      await makeRequest({
        axiosRequest: () =>
          axios.post(`/entities/${entityId}/data`, dataToCreate),
      })

      updatedListForStore = updatedListForStore.concat(...dataToCreate)
    }

    if (dataToUpdate.length > 0) {
      await makeRequest({
        axiosRequest: () =>
          axios.patch(`/entities/${entityId}/data`, dataToUpdate),
      })
      updatedListForStore = updatedListForStore.concat(dataToUpdate)
    }

    if (dataToDelete.length > 0) {
      dataToDelete.forEach(async (item) => {
        await makeRequest({
          axiosRequest: () =>
            axios.delete(`/entities/${entityId}/data/${item.dataId}`),
        })
      })
    }

    if (updateResponse) {
      dispatch({
        type: ENTITY_UPDATE,
        payload: updatedListForStore,
      })
    }
  }
}

export const updateEntityLogo = (
  entityId: Entity['id'],
  data: Entity
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () => axios.patch(`/entities/${entityId}`, data),
    successText: 'Entity Logo updated',
  })

  if (updateResponse) {
    dispatch({
      type: ENTITY_LOGO_UPDATE,
      payload: data,
    })
  }
}
