import { Button, makeStyles, Theme } from '@material-ui/core'
import AccountBox from '@material-ui/icons/AccountBox'
import { ClassNameMap } from '@material-ui/styles'
import React from 'react'
import { useCurrentUser } from '../../../utils/hooks/Users'
import useRouter from '../../../utils/useRouter'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  editAccountButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
  },
  editAccount: {
    marginRight: theme.spacing(1),
  },
}))

const EditAccountButton: React.SFC = () => {
  const classes = useStyles()
  const { history } = useRouter()
  const { currentUser } = useCurrentUser()

  const handleEditAccount = async () => {
    history.push(`/users/edit/${currentUser.id}`)
  }

  return (
    <Button className={classes.editAccountButton} onClick={handleEditAccount}>
      <AccountBox className={classes.editAccount} />
      Edit
    </Button>
  )
}

export default EditAccountButton
