import { Dispatch, AnyAction } from 'redux'
import { omit, omitBy, isNull } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'
import { Ranking } from './types'

export const RANKING_GET = 'RANKING_GET'
export const RANKING_ALL_PARAMS_GET = 'RANKING_ALL_PARAMS_GET'
export const RANKING_UPDATE = 'RANKING_UPDATE'

export const getRanking = (rankingId: Ranking['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(
        `/rankings/${rankingId}?relations=parameters,offers,filters,urls,sorting`
      ),
  })

  const getAllParamsResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(
        `/rankings/${rankingId}/offers-parameters?relations=parameters`
      ),
  })

  if (getResponse) {
    dispatch({
      type: RANKING_GET,
      payload: { ...getResponse.data, allParams: getAllParamsResponse.data },
    })
  }
}

export const getRankingAllParams = (rankingId: Ranking['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getAllParamsResponse = await makeRequest({
    axiosRequest: () =>
      axios.get(
        `/rankings/${rankingId}/offers-parameters?relations=parameters`
      ),
  })

  if (getAllParamsResponse) {
    dispatch({
      type: RANKING_ALL_PARAMS_GET,
      payload: getAllParamsResponse.data,
    })
  }
}

export const updateRanking = (rankingId: Ranking['id'], ranking: Ranking) => {
  const rankingData = omit(omitBy(ranking, isNull), [
    'rankingsParameters',
    'rankingOffers',
    'rankingsOffers',
    'rankingCols',
    'rankingsSorting',
    'tableData',
    'allParams',
    'rankingsFilters',
    'rankingUrls',
  ])
  const rankingDataForApi = {
    ...rankingData,
  }

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(`/rankings/${rankingId}`, rankingDataForApi),
      successText: ' ranking updated',
    })

    if (updateResponse) {
      dispatch({
        type: RANKING_UPDATE,
        payload: ranking,
      })
    }
  }
}
