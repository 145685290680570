import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { FormAgreement } from './types'

export const ADD_AGREEMENT_TO_FORM = 'ADD_AGREEMENT_TO_FORM'
export const REMOVE_AGREEMENT_FROM_FORM = 'REMOVE_AGREEMENT_FROM_FORM'

export const addAgreementToForm = (
  formId: FormAgreement['formId'],
  agreementId: FormAgreement['agreementId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/forms/${formId}/agreements/${agreementId}`),
    successText: 'Agreement added to form',
  })

  if (createResponse) {
    dispatch({
      type: ADD_AGREEMENT_TO_FORM,
      payload: {
        agreement: createResponse.data,
      },
    })
  }
}

export const removeAgreementFromForm = (
  formId: FormAgreement['formId'],
  agreementId: FormAgreement['agreementId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/forms/${formId}/agreements/${agreementId}`),
    successText: 'Agreement removed from form',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_AGREEMENT_FROM_FORM,
      payload: {
        formId,
        agreementId,
      },
    })
  }
}
