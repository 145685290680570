import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Debtor } from './types'

export const DEBTORS_BASES_GET = 'DEBTORS_BASES_GET'
export const DEBTORS_BASE_ADD = 'DEBTORS_BASE_ADD'
export const DEBTORS_BASE_REMOVE = 'DEBTORS_BASE_REMOVE'

export const getAllDebtorsBases = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/debtors-bases'),
  })

  if (getResponse) {
    dispatch({
      type: DEBTORS_BASES_GET,
      payload: getResponse.data,
    })
  }
}

export const addDebtorsBase = (debtorsBase: Debtor) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/debtors-bases', {
        name: debtorsBase.name,
      }),
    successText: 'Debtors base added',
  })

  if (createResponse) {
    dispatch({
      type: DEBTORS_BASE_ADD,
      payload: createResponse.data,
    })
  }
}

export const removeDebtorsBase = (debtorsBaseId: Debtor['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/debtors-bases/${debtorsBaseId}`),
    successText: 'Debtors removed',
  })

  if (deleteResponse) {
    dispatch({
      type: DEBTORS_BASE_REMOVE,
      payload: debtorsBaseId,
    })
  }
}
