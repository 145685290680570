import createMuiTheme, {
  ThemeOptions,
  Theme,
} from '@material-ui/core/styles/createMuiTheme'
import overrides from './overrides'

import palette from './palette'
import typography from './typography'

const theme: Theme = createMuiTheme({
  palette,
  typography,
  overrides,
} as ThemeOptions)

export default theme
