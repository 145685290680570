import {
  getAll,
  initialState,
  InitialStateType,
} from '../../utils/baseReducers'
import { WebsiteActionsTypes } from './types'
import { WEBSITES_GET } from './websitesActions'

const websitesReducer = (
  state: InitialStateType = initialState,
  action: WebsiteActionsTypes
) => {
  switch (action.type) {
    case WEBSITES_GET: {
      return getAll(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default websitesReducer
