import {
  add,
  getAll,
  initialState,
  InitialStateType,
} from '../../utils/baseReducers'
import { AGREEMENTS_ADD, AGREEMENTS_GET } from './agreementsActions'

import { AgreementsActionsTypes } from './types'

const agreementsReducer = (
  state: InitialStateType = initialState,
  action: AgreementsActionsTypes
) => {
  switch (action.type) {
    case AGREEMENTS_GET: {
      return getAll(state, action.payload)
    }

    case AGREEMENTS_ADD: {
      return add(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default agreementsReducer
