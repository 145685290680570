import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'

export const SERVICES_GET = 'SERVICES_GET'

export const getAllServices = () => async (dispatch: Dispatch<AnyAction>) => {
  const response = await makeRequest({
    axiosRequest: () => axios.get('/services'),
  })

  if (response) {
    dispatch({
      type: SERVICES_GET,
      payload: response.data,
    })
  }
}
