import {
  AFFILIATES_GET,
  AFFILIATE_ADD,
  AFFILIATE_UPDATE,
  AFFILIATE_REMOVE,
} from './affiliatesActions'
import {
  add,
  update,
  getAll,
  initialState,
  remove,
  InitialStateType,
} from '../../utils/baseReducers'
import { AffiliateActions } from './types'

const affiliatesReducer = (
  state: InitialStateType = initialState,
  action: AffiliateActions
) => {
  switch (action.type) {
    case AFFILIATES_GET: {
      return getAll(state, action.payload)
    }

    case AFFILIATE_ADD: {
      return add(state, action.payload)
    }

    case AFFILIATE_UPDATE: {
      return update(state, action.payload)
    }

    case AFFILIATE_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default affiliatesReducer
