import React from 'react'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { Typography, makeStyles } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) => ({
  finelfTitle: {
    color: theme.palette.white,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(1),
    fontSize: '29px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
    },
    width: 'auto',
  },
}))

const AssistantLogo = () => {
  const classes = useStyles()

  return (
    <>
      <RouterLink to="/">
        <img alt="Finelf Assistant" src="/images/logo.png" height="60px" />
      </RouterLink>
      <RouterLink to="/">
        <Typography className={classes.finelfTitle} component="div">
          {process.env.REACT_APP_IS_TEST_ENV === 'true'
            ? 'Finelf Assistant --TEST ENV--'
            : 'Finelf Assistant'}
        </Typography>
      </RouterLink>
    </>
  )
}

export default AssistantLogo
