import {
  add,
  getAll,
  initialState,
  InitialStateType,
  remove,
  update,
} from '../../utils/baseReducers'
import {
  ENTITY_FORM_INPUT_ADD,
  ENTITY_FORM_INPUT_REMOVE,
  ENTITY_FORM_INPUT_UPDATE,
  ENTITY_FORM_INPUTS_GET,
} from './entityFormInputsActions'

import { EntityFormInputsActionsTypes } from './types'

const entityFormInputsReducer = (
  state: InitialStateType = initialState,
  action: EntityFormInputsActionsTypes
) => {
  switch (action.type) {
    case ENTITY_FORM_INPUTS_GET: {
      return getAll(state, action.payload)
    }

    case ENTITY_FORM_INPUT_ADD: {
      return add(state, action.payload)
    }

    case ENTITY_FORM_INPUT_UPDATE: {
      return update(state, action.payload)
    }

    case ENTITY_FORM_INPUT_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default entityFormInputsReducer
