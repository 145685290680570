import React from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles'
import { createBrowserHistory } from 'history'
import { SnackbarProvider } from 'notistack'
import { Provider as StoreProvider } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { Router } from 'react-router-dom'

import './assets/scss/index.scss'

import './mixins/moment'
import './mixins/validate'

import ErrorBoundary from './components/ErrorBoundary'
import Notifier from './components/Notifier'
import routes from './routes'
import theme from './theme'
import { configureStore } from './store'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const history = createBrowserHistory() as any
export const store = configureStore()

const generateClassName = createGenerateClassName({
  seed: 'panel',
  productionPrefix: 'pp',
})

const App: React.FC = () => (
  <ErrorBoundary>
    <StoreProvider store={store}>
      <StylesProvider generateClassName={generateClassName}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider>
              <Router history={history}>{renderRoutes(routes)}</Router>
              <Notifier />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </StylesProvider>
    </StoreProvider>
  </ErrorBoundary>
)

export default App
