import React, { Suspense, useState, useEffect } from 'react'
import { LinearProgress, makeStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import { renderRoutes, RouteConfig } from 'react-router-config'

import NavBar from './NavBar'
import TopBar from './TopBar'
import { useSession } from '../../utils/hooks/Session'
import useRouter from '../../utils/useRouter'

const useStyles: () => ClassNameMap = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  topBar: {
    zIndex: 99,
    position: 'fixed',
    top: 0,
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    width: '100%',
  },
  navBar: {
    zIndex: 3,
    width: 256,
    minWidth: 256,
    flex: '0 0 auto',
    position: 'fixed',
    top: '66px',
  },
  content: {
    flex: '1 1 auto',
    width: '100%',
    paddingLeft: '256px',
    paddingTop: '64px',
  },
}))

type Props = {
  route: RouteConfig
}

const Dashboard: React.SFC<Props> = ({ route }) => {
  const classes = useStyles()
  const { authUser } = useSession()
  const router = useRouter()
  const [openNavBarMobile, setOpenNavBarMobile] = useState<boolean>(false)
  const handleNavBarMobileOpen = () => setOpenNavBarMobile(true)
  const handleNavBarMobileClose = () => setOpenNavBarMobile(false)

  useEffect(() => {
    if (JSON.stringify(authUser) === JSON.stringify({})) {
      router.history.push('/login')
    }
  }, [authUser, router.history])

  return (
    <div className={classes.root}>
      <TopBar
        className={classes.topBar}
        onOpenNavBarMobile={handleNavBarMobileOpen}
      />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </main>
      </div>
    </div>
  )
}

export default Dashboard
