import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Agreement } from './types'

export const AGREEMENT_GET = 'AGREEMENT_GET'
export const AGREEMENT_UPDATE = 'AGREEMENT_UPDATE'

export const getAgreement = (agreementId: Agreement['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/agreements/${agreementId}`),
  })
  if (getResponse) {
    dispatch({
      type: AGREEMENT_GET,
      payload: getResponse.data,
    })
  }
}

export const updateAgreement = (
  agreementId: Agreement['id'],
  agreement: Agreement
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () =>
        axios.patch(`/agreements/${agreementId}`, {
          ...agreement,
          parentId: parseInt(agreement.parentId as string),
        }),
      successText: 'Agreement updated',
    })

    if (updateResponse) {
      dispatch({
        type: AGREEMENT_UPDATE,
        payload: agreement,
      })
    }
  }
}
