import React, { Suspense } from 'react'
import { AppBar, LinearProgress, Toolbar, makeStyles } from '@material-ui/core'

import { renderRoutes, RouteConfig } from 'react-router-config'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

import AssistantLogo from '../../components/AssistantLogo'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    background:
      process.env.REACT_APP_IS_TEST_ENV === 'true' ? 'red' : undefined,
  },
  content: {
    height: '100%',
    paddingTop: 56,
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
}))

const Auth: React.SFC<{ route: RouteConfig }> = ({ route }) => {
  const classes = useStyles()

  return (
    <>
      <AppBar color="primary" className={classes.root}>
        <Toolbar>
          <AssistantLogo />
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </>
  )
}

export default Auth
