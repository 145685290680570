import { Dispatch, AnyAction } from 'redux'
import axios, { makeRequest } from '../../utils/axios'

import { Offer, OfferDebtor } from './types'

export const ADD_DEBTORS_TO_OFFER = 'ADD_DEBTORS_TO_OFFER'
export const REMOVE_DEBTORS_FROM_OFFER = 'REMOVE_DEBTORS_FROM_OFFER'

export const addDebtorToOffer = (
  offerId: Offer['id'],
  debtor: OfferDebtor
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/offers/${offerId}/debtors-bases`, {
        debtorsBaseId: debtor.id,
      }),
    successText: 'Debtor added to offer',
  })

  if (createResponse) {
    dispatch({
      type: ADD_DEBTORS_TO_OFFER,
      payload: {
        offerId,
        debtor,
      },
    })
  }
}

export const removeDebtorFromOffer = (
  offerId: Offer['id'],
  debtorId: OfferDebtor['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/offers/${offerId}/debtors-bases/${debtorId}`),
    successText: 'Debtor removed from offer',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_DEBTORS_FROM_OFFER,
      payload: {
        offerId,
        debtorId,
      },
    })
  }
}
