import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { DictionaryParameter, Parameter } from './types'

export const DICTIONARY_PARAMETER_ADD = 'DICTIONARY_PARAMETER_ADD'
export const DICTIONARY_PARAMETER_UPDATE = 'DICTIONARY_PARAMETER_UPDATE'
export const DICTIONARY_PARAMETER_REMOVE = 'DICTIONARY_PARAMETER_REMOVE'

export const addDictionaryParameter = (
  parameterId: Parameter['id'],
  DictionaryParameter: DictionaryParameter
) => async (dispatch: Dispatch<AnyAction>) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post(`/parameters/${parameterId}/dictionary`, {
        name: DictionaryParameter.name,
        languageId: DictionaryParameter.languageId,
      }),
    successText: 'DictionaryParameter added',
  })

  if (createResponse) {
    dispatch({
      type: DICTIONARY_PARAMETER_ADD,
      payload: createResponse.data,
    })
  }
}

export const updateDictionaryParameter = (
  parameterId: Parameter['id'],
  DictionaryParameter: DictionaryParameter
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/parameters/${parameterId}/dictionary`, DictionaryParameter),
    successText: 'DictionaryParameter updated',
  })

  if (updateResponse) {
    dispatch({
      type: DICTIONARY_PARAMETER_UPDATE,
      payload: DictionaryParameter,
    })
  }
}

export const removeDictionaryParameter = (
  parameterId: Parameter['id'],
  languageId: DictionaryParameter['languageId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/parameters/${parameterId}/dictionary/${languageId}`),
    successText: 'DictionaryParameter removed',
  })

  if (deleteResponse) {
    dispatch({
      type: DICTIONARY_PARAMETER_REMOVE,
      payload: languageId,
    })
  }
}
