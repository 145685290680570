import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { dispatchResetReducer } from '../../utils/baseReducers'
import { Branch } from '../../views/Branches/types'

import { Entity, EntityFormValues } from './types'

export const ENTITIES_GET = 'ENTITIES_GET'
export const ENTITY_ADD = 'ENTITY_ADD'
export const RESET_ENTITES = 'RESET_ENTITES'

export const getAllEntities = (branchId?: Entity['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  dispatchResetReducer(dispatch, RESET_ENTITES)
  const entitiesUrl =
    (branchId && `/branches/${branchId}?relations=entities,offers`) ||
    '/entities?relations=data'
  const response = await makeRequest({
    axiosRequest: () => axios.get(entitiesUrl),
  })

  const entitiesData = (branchId && response.data.entities) || response.data

  if (response) {
    dispatch({
      type: ENTITIES_GET,
      payload: entitiesData,
    })
  }
}

export const addEntity = (entity: EntityFormValues, branchId: Branch['id']) => {
  const { name, ...enitnyDataValues } = entity

  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () =>
        axios.post('/entities', {
          name: name.value,
          branchId,
        }),
      successText: 'Entity added',
    })

    if (enitnyDataValues) {
      const mappedValues = Object.keys(enitnyDataValues).map(
        (key: string) => enitnyDataValues[key]
      )

      await makeRequest({
        axiosRequest: () =>
          axios.post(`/entities/${createResponse.data.id}/data`, mappedValues),
      })
    }

    if (createResponse) {
      dispatch({
        type: ENTITY_ADD,
        payload: createResponse.data,
      })
    }
  }
}
