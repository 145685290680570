import { getAll, initialState } from '../../utils/baseReducers'
import { FORMS_GET } from './formsActions'
import { FormsActionsTypes } from './types'

const formsReducer = (state = initialState, action: FormsActionsTypes) => {
  switch (action.type) {
    case FORMS_GET: {
      return getAll(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default formsReducer
