import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'

export const FORMS_GET = 'FORMS_GET'

export const getAllForms = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/forms'),
  })

  if (getResponse) {
    dispatch({
      type: FORMS_GET,
      payload: getResponse.data,
    })
  }
}
