import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { DictionaryTab } from './types'

export const DICTIONARY_TAB_ADD = 'DICTIONARY_TAB_ADD'
export const DICTIONARY_TAB_UPDATE = 'DICTIONARY_TAB_UPDATE'
export const DICTIONARY_TABS_GET = 'DICTIONARY_TABS_GET'
export const DICTIONARY_TAB_REMOVE = 'DICTIONARY_TAB_REMOVE'

export const getDictionaryTabs = (tabId: number | string) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const response = await makeRequest({
    axiosRequest: () => axios.get(`tabs/${tabId}/?relations=dictionaryTabs`),
  })

  if (response) {
    dispatch({
      type: DICTIONARY_TABS_GET,
      payload: response.data.dictionaryTabs,
    })
  }
}

export const addDictionaryTab = (
  tabId: DictionaryTab['tabId'],
  dictionaryTab: DictionaryTab
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () =>
        axios.post(`/tabs/${tabId}/dictionary`, {
          name: dictionaryTab.name,
          languageId: dictionaryTab.languageId,
        }),
      successText: 'DictionaryTab added',
    })

    if (createResponse) {
      dispatch({
        type: DICTIONARY_TAB_ADD,
        payload: createResponse.data,
      })
    }
  }
}

export const updateDictionaryTab = (
  tabId: DictionaryTab['tabId'],
  dictionaryTab: DictionaryTab
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () => axios.patch(`/tabs/${tabId}/dictionary`, dictionaryTab),
    successText: 'DictionaryTab updated',
  })

  if (updateResponse) {
    dispatch({
      type: DICTIONARY_TAB_UPDATE,
      payload: dictionaryTab,
    })
  }
}

export const removeDictionaryTab = (
  tabId: DictionaryTab['tabId'],
  languageId: DictionaryTab['languageId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/tabs/${tabId}/dictionary/${languageId}`),
    successText: 'DictionaryTab removed',
  })

  if (deleteResponse) {
    dispatch({
      type: DICTIONARY_TAB_REMOVE,
      payload: { tabId, languageId },
    })
  }
}
