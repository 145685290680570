import { Dispatch, AnyAction } from 'redux'
import axios, { makeRequest } from '../../utils/axios'

import { User } from './types'

export const USER_GET = 'USER_GET'
export const USER_UPDATE = 'USER_UPDATE'

export const getUser = (userId: User['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/users/${userId}`),
  })

  if (getResponse) {
    dispatch({
      type: USER_GET,
      payload: getResponse.data,
    })
  }
}

export const updateUser = (user: User) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const updateResponse = await makeRequest({
    axiosRequest: () => axios.patch('/users', user),
    successText: 'User updated',
  })

  if (updateResponse) {
    dispatch({
      type: USER_UPDATE,
      payload: user,
    })
  }
}
