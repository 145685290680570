import React, { MouseEvent } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'

type Props = {
  sectionName?: string
  buttonText?: string
  pageName: string
  onButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

const PageHeader: React.SFC<Props> = ({
  pageName,
  buttonText,
  onButtonClick,
  sectionName,
}) => (
  <Grid alignItems="flex-end" container justify="space-between" spacing={3}>
    <Grid item>
      {sectionName && (
        <Typography component="h2" gutterBottom variant="overline">
          {sectionName}
        </Typography>
      )}
      <Typography component="h1" variant="h3">
        {pageName}
      </Typography>
    </Grid>
    <Grid item>
      {buttonText && (
        <Button color="primary" variant="contained" onClick={onButtonClick}>
          {buttonText}
        </Button>
      )}
    </Grid>
  </Grid>
)

export default PageHeader
