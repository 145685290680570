import React, { useState } from 'react'
import {
  Button,
  Collapse,
  colors,
  ListItem,
  Theme,
  SvgIconTypeMap,
  makeStyles,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ClassNameMap } from '@material-ui/styles'
import classNames from 'classnames'
import {
  NavLink as RouterLink,
  NavLinkProps,
  LinkProps,
} from 'react-router-dom'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    color: 'red',
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&active': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      '& $icon': {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
}))

type Props = {
  children?: React.ReactNode
  className?: string
  depth: number
  href?: string | string[]
  icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>
  open?: boolean
  title: LinkProps['to']
}

const LinkRef = React.forwardRef<
  HTMLAnchorElement & NavLinkProps,
  NavLinkProps
>((props, ref) => (
  <div style={{ flexGrow: 1 }}>
    <RouterLink innerRef={ref} {...props} />
  </div>
))

const NavigationListItem: React.SFC<Props> = ({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp = false,
  ...rest
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(openProp)

  const handleToggle = () => {
    setOpen(() => !open)
  }

  let paddingLeft = 8

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }

  const style = {
    paddingLeft,
  }

  if (children) {
    return (
      <ListItem
        {...rest}
        className={classNames(classes.item, className)}
        disableGutters
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} />}
          {title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ListItem
      {...rest}
      className={classNames(classes.itemLeaf, className)}
      disableGutters
    >
      <Button
        className={classNames(classes.buttonLeaf, `depth-${depth}`)}
        component={LinkRef}
        style={style}
        to={href as string}
      >
        {Icon && ((<Icon className={classes.icon} />) as React.ReactNode)}
        {title}
      </Button>
    </ListItem>
  )
}

export default NavigationListItem
