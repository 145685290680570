import { Dispatch, AnyAction } from 'redux'
import { omit, omitBy, isNull } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'
import { Debtor } from './types'

export const DEBTOR_GET = 'DEBTOR_GET'
export const DEBTOR_UPDATE = 'DEBTOR_UPDATE'
export const DEBTOR_LOGO_UPDATE = 'DEBTOR_LOGO_UPDATE'

export const getDebtor = (debtorId: Debtor['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/debtors-bases/${debtorId}`),
  })
  if (getResponse) {
    dispatch({
      type: DEBTOR_GET,
      payload: getResponse.data,
    })
  }
}

export const updateDebtor = (debtorId: Debtor['id'], debtor: Debtor) => {
  const debtorData = omit(omitBy(debtor, isNull), [
    'debtorParameters',
    'debtorOffers',
    'debtorCols',
    'tableData',
  ])

  return async (dispatch: Dispatch<AnyAction>) => {
    const updateResponse = await makeRequest({
      axiosRequest: () => axios.patch(`/debtors-bases/${debtorId}`, debtorData),
      successText: 'Debtor updated',
    })

    if (updateResponse) {
      dispatch({
        type: DEBTOR_UPDATE,
        payload: debtor,
      })
    }
  }
}

export const updateDebetorLogo = (
  debetorId: Debtor['id'],
  data: Debtor
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () => axios.patch(`/debtors-bases/${debetorId}`, data),
    successText: 'Debetor Logo updated',
  })

  if (updateResponse) {
    dispatch({
      type: DEBTOR_LOGO_UPDATE,
      payload: data,
    })
  }
}
