import axios from 'axios'
import { useAuthTokenInterceptor } from 'axios-jwt'
import { logout } from '../../views/Login/sessionActions'
import { store } from '../../App'
import axiosInstance from './index'

import { Token } from '../../views/Login/types'

type AuthResponse = {
  token: string
  refreshToken: string
}

const API_URL = process.env.REACT_APP_API_URL

if (!API_URL) {
  throw new Error('API_URL is not defined')
}

export const authResponseToAuthTokens = (response: AuthResponse) => ({
  accessToken: response.token,
  refreshToken: response.refreshToken,
})

const requestRefresh = async (refreshToken: Token['refreshToken']) => {
  try {
    const response = await axios.post(`${API_URL}/auth/refresh-token`, {
      refreshToken,
    })

    return response.data.token
  } catch (error) {
    store.dispatch(logout())

    return error
  }
}

useAuthTokenInterceptor(axiosInstance, { requestRefresh })
