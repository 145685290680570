import validate from 'validate.js'
import {
  parameterRangeDateType,
  parameterRangeDateTypeNumbers,
} from '../constants/types'

export const validateUrlWithEmptyValue = (...props: string[]) => {
  if (props[0] !== '') {
    return validate.validators.url(...props)
  }

  return false
}

export const useDateTimeValidation = () => {
  validate.extend(validate.validators.datetime, {
    parse: (value: string) => new Date(value).getTime(),
    format: (_: unknown, options: { dateOnly: boolean }) =>
      options.dateOnly ? 'YYYY-MM-DD' : 'YYYY-MM-DD hh:mm:ss',
  })
}

export const formatDateFromApi = (date?: null | string | number) => {
  if (date === undefined || date === null || date === '') {
    return date
  }

  const dateToFormat = new Date(date)
  let dd: number | string = dateToFormat.getDate()
  let mm: number | string = dateToFormat.getMonth() + 1
  const yyyy = dateToFormat.getFullYear()

  if (dd < 10) {
    dd = `0${dd}`
  }

  if (mm < 10) {
    mm = `0${mm}`
  }

  return `${yyyy}-${mm}-${dd}`
}

export const convertFromDaysToDateRange = (range: string) => {
  let extractedRange
  const [from, to] = range.split('-') as (string | number)[]

  Object.keys(parameterRangeDateTypeNumbers)
    .reverse()
    .some((key) => {
      if (
        (from as number) % parameterRangeDateTypeNumbers[key] === 0 &&
        (to as number) % parameterRangeDateTypeNumbers[key] === 0
      ) {
        extractedRange = {
          value: `${(from as number) / parameterRangeDateTypeNumbers[key]}-${
            (to as number) / parameterRangeDateTypeNumbers[key]
          }`,
          type: parseInt(key, 10),
          name: parameterRangeDateType[key],
        }

        return true
      }

      return false
    })

  return extractedRange
}

export const convertFromDateRangeToDays = (range: string, type: string) => {
  const [from, to] = range.split('-') as (string | number)[]

  return `${(from as number) * parameterRangeDateTypeNumbers[type]}-${
    (to as number) * parameterRangeDateTypeNumbers[type]
  }`
}
