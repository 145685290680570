import { AnyAction, Dispatch } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { EntityFormInput } from './types'

export const ENTITY_FORM_INPUT_GET = 'ENTITY_FORM_INPUT_GET'
export const ENTITY_FORM_INPUTS_GET = 'ENTITY_FORM_INPUTS_GET'
export const ENTITY_FORM_INPUT_ADD = 'ENTITY_FORM_INPUT_ADD'
export const ENTITY_FORM_INPUT_UPDATE = 'ENTITY_FORM_INPUT_UPDATE'
export const ENTITY_FORM_INPUT_REMOVE = 'ENTITY_FORM_INPUT_REMOVE'

export const getAllEntityFormInputs = () => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/data'),
  })

  if (getResponse) {
    dispatch({
      type: ENTITY_FORM_INPUTS_GET,
      payload: getResponse.data,
    })
  }
}

export const getEntityFormInput = (dataId: EntityFormInput['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get(`/data/${dataId}?relations=dictionaryData`),
  })

  if (getResponse) {
    dispatch({
      type: ENTITY_FORM_INPUT_GET,
      payload: getResponse.data,
    })
  }
}

export const addEntityFormInput = (entityFormInput: EntityFormInput) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/data', {
        name: entityFormInput.name,
        slug: entityFormInput.slug,
      }),
    successText: 'EntityFormInput added',
  })

  if (createResponse) {
    dispatch({
      type: ENTITY_FORM_INPUT_ADD,
      payload: createResponse.data,
    })
  }
}

export const updateEntityFormInput = (
  entityFormInput: EntityFormInput
) => async (dispatch: Dispatch<AnyAction>) => {
  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(`/data/${entityFormInput.id}`, entityFormInput),
    successText: 'EntityFormInput updated',
  })

  if (updateResponse) {
    dispatch({
      type: ENTITY_FORM_INPUT_UPDATE,
      payload: entityFormInput,
    })
  }
}

export const removeEntityFormInput = (
  entityFormInputId: EntityFormInput['id']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/data/${entityFormInputId}`),
    successText: 'EntityFormInput removed',
  })

  if (deleteResponse) {
    dispatch({
      type: ENTITY_FORM_INPUT_REMOVE,
      payload: entityFormInputId,
    })
  }
}
