import { PayloadList, PayloadSingle } from '../types/types'
import { Dispatch } from 'redux'

export interface InitialStateType {
  isLoading?: boolean
  loaded?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<{
    [key: string]: any
  }>
}

export interface InitialFormStateType {
  isLoading?: boolean
  loaded?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

export const dispatchResetReducer = (dispatch: Dispatch, actionType: string) =>
  dispatch({
    type: actionType,
  })

export const initialState: InitialStateType = {
  isLoading: true,
  loaded: false,
  data: [],
}

export const initialFormState: InitialFormStateType = {
  isLoading: true,
  loaded: false,
  data: {},
}

export const getAll = (state: InitialStateType, payload: PayloadList) => {
  return {
    ...state,
    loaded: true,
    isLoading: false,
    data: payload,
  }
}

export const getOne = (state: InitialStateType, payload: PayloadSingle) => {
  return {
    ...state,
    data: payload,
  }
}

export const getOneFromApi = (
  state: InitialFormStateType,
  payload: PayloadSingle
) => {
  return {
    ...state,
    loaded: true,
    isLoading: false,
    data: payload,
  }
}

export const updateOneFromApi = (
  state: InitialFormStateType,
  payload: PayloadSingle
) => {
  return {
    ...state,
    loaded: true,
    isLoading: false,
    data: { ...state.data, ...payload },
  }
}

export const add = (state: InitialStateType, payload: PayloadSingle) => {
  return {
    ...state,
    data: [...state.data, payload],
  }
}

export const update = (state: InitialStateType, updatedItem: PayloadSingle) => {
  return {
    ...state,
    data: state.data.map((item) => {
      if (item.id === updatedItem.id) {
        return updatedItem
      }

      return item
    }),
  }
}

export const remove = (state: InitialStateType, payload: string | number) => {
  return {
    ...state,
    data: state.data.filter((item) => item.id !== payload),
  }
}
