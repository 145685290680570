import {
  Card,
  CardContent,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import { ClassNameMap } from '@material-ui/styles'
import React from 'react'

import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import { useWebsiteList } from '../../utils/hooks/Websites'
import { Website } from './types'

const useStyles: () => ClassNameMap = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(3),
  },
  content: {
    padding: 0,
  },
  tableBoldFont: {
    fontWeight: 'bold',
  },
  tableError: {
    background: '#F45B69',
  },
  flexCell: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

const Overview = () => {
  const classes = useStyles()
  const { websites } = useWebsiteList()

  return (
    <Page className={classes.root} title="Overview">
      <PageHeader pageName="Domains" />
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <div>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell>Domain</TableCell>
                  <TableCell>CreatedOn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {websites.map((website: Website) => (
                  <TableRow key={website.id}>
                    <TableCell>
                      <Typography>
                        <Link
                          href={website.domain}
                          target="_BLANK"
                          className={classes.link}
                          variant="body2"
                        >
                          {website.domain}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {new Date(website.createdOn).toLocaleDateString(
                          'pl-PL'
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </Page>
  )
}

export default Overview
