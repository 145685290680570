import { AnyAction, Dispatch } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { OfferFormsAgreement } from './types'

export const ADD_FORMS_AGREEMENT_TO_OFFER = 'ADD_FORMS_AGREEMENT_TO_OFFER'
export const REMOVE_FORMS_AGREEMENT_FROM_OFFER =
  'REMOVE_FORMS_AGREEMENT_FROM_OFFER'
export const UPDATE_FORMS_AGREEMENT_TO_OFFER = 'UPDATE_FORMS_AGREEMENT_TO_OFFER'

export const addFormsAgreementToOffer = (
  formsAgreement: OfferFormsAgreement,
  offerId: OfferFormsAgreement['offerId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const data: OfferFormsAgreement = {} as OfferFormsAgreement

  if (formsAgreement.content) {
    data.content = formsAgreement.content
  }

  if (formsAgreement.isRequired !== undefined) {
    data.isRequired = formsAgreement.isRequired
  }

  if (formsAgreement.type !== undefined) {
    data.type = formsAgreement.type
  }

  const createResponse = await makeRequest({
    axiosRequest: () => axios.post(`/offers/${offerId}/forms-agreements`, data),
    successText: 'Forms Agreement added to offer',
  })

  if (createResponse) {
    dispatch({
      type: ADD_FORMS_AGREEMENT_TO_OFFER,
      payload: {
        ...createResponse.data,
      },
    })
  }
}

export const updateFormsAgreementToOffer = (
  formsAgreement: OfferFormsAgreement,
  offerId: OfferFormsAgreement['offerId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const data: OfferFormsAgreement = {} as OfferFormsAgreement

  if (formsAgreement.content) {
    data.content = formsAgreement.content
  }

  if (formsAgreement.isRequired !== undefined) {
    data.isRequired = formsAgreement.isRequired
  }

  if (formsAgreement.type !== undefined) {
    data.type = formsAgreement.type
  }

  const updateResponse = await makeRequest({
    axiosRequest: () =>
      axios.patch(
        `/offers/${offerId}/forms-agreements/${formsAgreement.id}`,
        data
      ),
    successText: 'Forms Agreement updated',
  })

  if (updateResponse) {
    dispatch({
      type: UPDATE_FORMS_AGREEMENT_TO_OFFER,
      payload: {
        ...formsAgreement,
      },
    })
  }
}

export const removeFormsAgreementFromOffer = (
  id: OfferFormsAgreement['id'],
  offerId: OfferFormsAgreement['offerId']
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/offers/${offerId}/forms-agreements/${id}`),
    successText: 'Forms Agreement removed from Offer',
  })

  if (deleteResponse) {
    dispatch({
      type: REMOVE_FORMS_AGREEMENT_FROM_OFFER,
      payload: id as number,
    })
  }
}
