import { Product } from './types'
import { Dispatch, AnyAction } from 'redux'
import { isNull, omitBy } from 'lodash'

import axios, { makeRequest } from '../../utils/axios'

export const PRODUCT_ADD = 'PRODUCT_ADD'
export const PRODUCTS_GET = 'PRODUCTS_GET'
export const PRODUCT_REMOVE = 'PRODUCT_REMOVE'

export const getAllProducts = (withRelations?: boolean) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const productsUrl =
    (withRelations && '/products?relations=products') || '/products'
  const response = await makeRequest({
    axiosRequest: () => axios.get(productsUrl),
  })

  if (response) {
    dispatch({
      type: PRODUCTS_GET,
      payload: response.data,
    })
  }
}

export const addProduct = (product: Product) => {
  const productData = omitBy(product, isNull)

  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () => axios.post('/products', productData),
      successText: 'Product Category added',
    })

    if (createResponse) {
      dispatch({
        type: PRODUCT_ADD,
        payload: createResponse.data,
      })
    }
  }
}

export const removeProduct = (productId: Product['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/products/${productId}`),
    successText: 'Product Category removed',
  })

  if (deleteResponse) {
    dispatch({
      type: PRODUCT_REMOVE,
      payload: productId,
    })
  }
}
