import { Product, TabParameter } from './types'
import { Dispatch, AnyAction } from 'redux'

import axios, { makeRequest } from '../../utils/axios'
import { Parameter } from '../Parameters/types'

export const PRODUCT_TAB_PARAMETERS_ADD = 'PRODUCT_TAB_PARAMETERS_ADD'
export const PRODUCT_TAB_PARAMETER_REMOVE = 'PRODUCT_TAB_PARAMETER_REMOVE'

export const addProductTabParameters = (
  productTabId: Product['id'],
  productTabParameters: Parameter[],
  latestItemUpdatedIndex: number
) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const createResponse = await makeRequest({
      axiosRequest: () =>
        axios.post(
          `/tabs/${productTabId}/parameters`,
          productTabParameters.map((parameter) => ({
            id: parameter.id,
            parameterId: parameter.id,
          }))
        ),
      successText: 'Product Tab added',
    })

    if (createResponse) {
      dispatch({
        type: PRODUCT_TAB_PARAMETERS_ADD,
        payload: {
          parameters: createResponse.data.map(
            (parameter: Parameter, index: number) => ({
              ...parameter,
              parameter: productTabParameters[index],
            })
          ),
          productTabId,
          latestItemUpdatedIndex,
        },
      })
    }
  }
}

export const removeProductTabParameter = (
  productTabId: Product['id'],
  parameter: TabParameter,
  latestItemUpdatedIndex: number
) => async (dispatch: Dispatch<AnyAction>) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () =>
      axios.delete(`/tabs/${productTabId}/parameters/${parameter.parameterId}`),
    successText: 'Product Tab removed',
  })

  if (deleteResponse) {
    dispatch({
      type: PRODUCT_TAB_PARAMETER_REMOVE,
      payload: {
        productTabId,
        parameter,
        latestItemUpdatedIndex,
      },
    })
  }
}
