import {
  add,
  getAll,
  initialState,
  remove,
  update,
  InitialStateType,
} from '../../utils/baseReducers'
import { BranchActionsTypes } from './types'
import {
  BRANCH_ADD,
  BRANCH_REMOVE,
  BRANCH_UPDATE,
  BRANCHES_GET,
} from './branchesActions'

const branchesReducer = (
  state: InitialStateType = initialState,
  action: BranchActionsTypes
) => {
  switch (action.type) {
    case BRANCHES_GET: {
      return getAll(state, action.payload)
    }

    case BRANCH_ADD: {
      return add(state, action.payload)
    }

    case BRANCH_UPDATE: {
      return update(state, action.payload)
    }

    case BRANCH_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default branchesReducer
