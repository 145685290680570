import {
  getOneFromApi,
  initialFormState,
  InitialFormStateType,
} from '../../utils/baseReducers'
import { FORM_GET } from './formActions'
import {
  ADD_AGREEMENT_TO_FORM,
  REMOVE_AGREEMENT_FROM_FORM,
} from './formAgreementsActions'

import { FormActionsTypes, FormAgreement } from './types'

const formReducer = (
  state: InitialFormStateType = initialFormState,
  action: FormActionsTypes
) => {
  switch (action.type) {
    case FORM_GET: {
      return getOneFromApi(state, action.payload)
    }

    case ADD_AGREEMENT_TO_FORM: {
      const agreements = state.data.formsAgreements || []
      const newData = {
        ...state.data,
        formsAgreements: agreements.concat({ ...action.payload.agreement }),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_AGREEMENT_FROM_FORM: {
      return {
        ...state,
        data: {
          ...state.data,
          formsAgreements: state.data.formsAgreements.filter(
            (agreement: FormAgreement) => {
              return (
                agreement.agreementId !==
                parseInt(action.payload.agreementId.toString())
              )
            }
          ),
        },
      }
    }

    default: {
      return state
    }
  }
}

export default formReducer
