import {
  DEBTORS_BASE_ADD,
  DEBTORS_BASE_REMOVE,
  DEBTORS_BASES_GET,
} from './debtorsBasesActions'
import {
  add,
  getAll,
  initialState,
  remove,
  InitialStateType,
} from '../../utils/baseReducers'

import { DebtorsActionsTypes } from './types'

const debtorsBasesReducer = (
  state: InitialStateType = initialState,
  action: DebtorsActionsTypes
) => {
  switch (action.type) {
    case DEBTORS_BASES_GET: {
      return getAll(state, action.payload)
    }

    case DEBTORS_BASE_ADD: {
      return add(state, action.payload)
    }

    case DEBTORS_BASE_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default debtorsBasesReducer
