import { ENTITY_ADD, ENTITIES_GET, RESET_ENTITES } from './entitiesActions'
import {
  add,
  getAll,
  initialState,
  InitialStateType,
} from '../../utils/baseReducers'
import { EntitiesActionsTypes, EntityFormValues } from './types'

const entitiesReducer = (
  state: InitialStateType = initialState,
  action: EntitiesActionsTypes
) => {
  switch (action.type) {
    case ENTITIES_GET: {
      const mappedEntities = action.payload.map((item) => {
        const newItem = {
          ...item,
          id: item.entityId || item.id,
          ...item.entity,
        }

        newItem &&
          newItem.entitiesData &&
          newItem.entitiesData.some((entityData: EntityFormValues) => {
            if (entityData.data.slug === 'country' && entityData.value !== '') {
              newItem.name = `[${entityData.value?.toUpperCase()}] ${
                newItem.name
              }`
              return true
            }
            return false
          })

        return newItem
      })
      return getAll(state, mappedEntities)
    }

    case ENTITY_ADD: {
      return add(state, action.payload)
    }

    case RESET_ENTITES: {
      return initialState
    }

    default: {
      return state
    }
  }
}

export default entitiesReducer
