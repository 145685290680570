import { get, remove, sortBy } from 'lodash'
import { formatDateFromApi } from '../../utils/formUtils'
import {
  getOneFromApi,
  initialFormState,
  updateOneFromApi,
  InitialFormStateType,
} from '../../utils/baseReducers'
import {
  ADD_SORTING_TO_RANKING,
  REMOVE_SORTING_FROM_RANKING,
} from './rankingSortingActions'

import {
  RankingActionsTypes,
  RankingColumn,
  RankingFilter,
  RankingOffer,
  RankingParameter,
} from './types'

import {
  RANKING_GET,
  RANKING_ALL_PARAMS_GET,
  RANKING_UPDATE,
} from './rankingActions'
import {
  ADD_COLUMN_TO_RANKING,
  ADD_RANKINGS_PARAMETERS,
  REMOVE_COLUMN_FROM_RANKING,
  REMOVE_RANKINGS_PARAMETER,
  UPDATE_RANKING_COLUMNS_LIST,
} from './rankingColumnsActions'
import {
  ADD_OFFERS_TO_RANKING,
  REMOVE_OFFER_FROM_RANKING,
  UPDATE_RANKING_OFFERS_LIST,
  UPDATE_RANKING_OFFER_TAG,
} from './rankingOffersActions'

import {
  ADD_FILTER_TO_RANKING,
  REMOVE_FILTER_FROM_RANKING,
} from './rankingFiltersActions'

const rankingReducer = (
  state: InitialFormStateType = initialFormState,
  action: RankingActionsTypes
) => {
  switch (action.type) {
    case RANKING_GET: {
      const {
        payload: { hotOfferId, hotOfferStart, hotOfferEnd },
      } = action
      const ranking = {
        ...action.payload,
        hotOfferId: hotOfferId !== 0 ? hotOfferId : '',
        hotOfferStart:
          hotOfferId !== 0 && hotOfferId !== null
            ? formatDateFromApi(hotOfferStart)
            : undefined,
        hotOfferEnd:
          hotOfferId !== 0 && hotOfferId !== null
            ? formatDateFromApi(hotOfferEnd)
            : undefined,
      }

      const rankingsParameters = get(ranking, 'rankingsParameters', []).map(
        (item: RankingParameter) => ({
          ...item.parameter,
          columnId: item.parameterId,
          columnType: 'parameter',
          priority: item.priority,
          isSponsoredRanking: item.isSponsoredRanking ?? false,
        })
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:Uncallable argument error becouse of naming ts glich
      const rankingsOffers = get(ranking, 'rankingsOffers', []).map(
        (item: RankingOffer) => ({
          ...item.offer,
          tag: item.tag,
          offerId: item.offerId,
          priority: item.priority,
          isFixedPriority: item.isFixedPriority,
          isSponsored: item.isSponsored,
        })
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:Uncallable argument error becouse of naming ts glich
      ranking.rankingsFilters = get(ranking, 'rankingsFilters', []).map(
        (item: RankingFilter) => ({
          ...item,
          type: item.parameter.type,
          id: item.parameterId,
        })
      )

      ranking.rankingCols = sortBy(rankingsParameters, (col) => col.priority)
      ranking.rankingOffers = sortBy(rankingsOffers, (offer) => offer.priority)

      return getOneFromApi(state, ranking)
    }

    case RANKING_ALL_PARAMS_GET: {
      return updateOneFromApi(state, {
        ...state.data,
        allParams: action.payload,
      })
    }

    case RANKING_UPDATE: {
      const ranking = {
        ...action.payload,
        hotOfferStart: formatDateFromApi(action.payload.hotOfferStart),
        hotOfferEnd: formatDateFromApi(action.payload.hotOfferEnd),
      }

      return updateOneFromApi(state, ranking)
    }

    case ADD_FILTER_TO_RANKING: {
      const filters = state.data.rankingsFilters || []
      const newData = {
        ...state.data,
        rankingsFilters: filters.concat({
          ...action.payload,
        }),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_FILTER_FROM_RANKING: {
      return {
        ...state,
        data: {
          ...state.data,
          rankingsFilters: [...state.data.rankingsFilters].filter(
            (item) => Number(action.payload.id) !== item.id
          ),
        },
      }
    }

    case ADD_RANKINGS_PARAMETERS: {
      const rankingsParameters = state.data.rankingsParameters || []
      const newRankingParameter = action.payload

      const newData = {
        ...state.data,
        rankingsParameters: [...rankingsParameters, ...newRankingParameter],
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_RANKINGS_PARAMETER: {
      const rankingsParameterToRemove = action.payload
      const currentRankingParameters: RankingParameter[] = [
        ...state.data.rankingsParameters,
      ]
      const newRankingsParameters = currentRankingParameters.filter(
        (rankingParameter: RankingParameter) => {
          return !(
            rankingParameter.parameterId ===
              parseInt(rankingsParameterToRemove.columnId as string, 10) &&
            rankingParameter.isSponsoredRanking ===
              rankingsParameterToRemove.isSponsoredRanking
          )
        }
      )

      return {
        ...state,
        data: {
          ...state.data,
          rankingsParameters: newRankingsParameters,
        },
      }
    }

    case ADD_COLUMN_TO_RANKING: {
      const columns = state.data.rankingCols || []
      const newData = {
        ...state.data,
        rankingCols: columns.concat({
          ...action.payload,
          columnId: action.payload.id,
          isSponsoredRanking: action.payload.isSponsoredRanking,
        }),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_COLUMN_FROM_RANKING: {
      const newList = [...state.data.rankingCols]
      // Array to prevent removal of two same items
      const removedIds: number[] = []

      remove(newList, (item) => {
        const shouldRemove =
          parseInt(item.columnId, 10) ===
            parseInt(action.payload.columnId as string, 10) &&
          item.columnType === action.payload.columnType &&
          item.isSponsoredRanking === action.payload.isSponsoredRanking &&
          !removedIds.includes(item.columnId)

        if (shouldRemove) {
          removedIds.push(item.columnId)
          return true
        }

        return false
      })

      return {
        ...state,
        data: {
          ...state.data,
          rankingCols: newList.map((item, index) => ({
            ...item,
            priority: index,
          })),
        },
      }
    }

    case UPDATE_RANKING_COLUMNS_LIST: {
      return {
        ...state,
        data: {
          ...state.data,
          rankingCols: action.payload.list.map((item, index) => ({
            ...item,
            priority: index,
          })),
        },
      }
    }

    case ADD_SORTING_TO_RANKING: {
      const sorting = state.data.rankingsSorting || []
      const newData = {
        ...state.data,
        rankingsSorting: sorting.concat(action.payload),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_SORTING_FROM_RANKING: {
      const newList = [...state.data.rankingsSorting]

      remove(
        newList,
        (item) =>
          parseInt(item.parameterId, 10) ===
          parseInt(action.payload.parameterId as string, 10)
      )

      return {
        ...state,
        data: {
          ...state.data,
          rankingsSorting: newList.map((item) => ({
            ...item,
          })),
        },
      }
    }

    case ADD_OFFERS_TO_RANKING: {
      const offers = state.data.rankingOffers || []
      let currentPriorit = action.payload.latestPrority
      // eslint-disable-next-line no-plusplus
      const newItems = action.payload.offers.map((item: RankingOffer) => ({
        ...item,
        offerId: item.id,
        priority: ++currentPriorit,
      }))
      const newData = {
        ...state.data,
        rankingOffers: offers.concat(newItems),
      }

      return {
        ...state,
        data: newData,
      }
    }

    case REMOVE_OFFER_FROM_RANKING: {
      return {
        ...state,
        data: {
          ...state.data,
          rankingOffers: [...state.data.rankingOffers].filter(
            (item) => action.payload.offerId !== item.offerId
          ),
        },
      }
    }

    case UPDATE_RANKING_OFFERS_LIST: {
      return {
        ...state,
        data: {
          ...state.data,
          rankingOffers: action.payload.list.map((item, index) => ({
            ...item,
            priority: index,
          })),
        },
      }
    }

    case UPDATE_RANKING_OFFER_TAG: {
      return {
        ...state,
        data: {
          ...state.data,
          rankingOffers: state.data.rankingOffers.map((offer: RankingOffer) => {
            if (offer.offerId === action.payload.offerId) {
              return {
                ...offer,
                ...action.payload,
              }
            }

            return offer
          }),
        },
      }
    }

    default: {
      return state
    }
  }
}

export default rankingReducer
