import {
  PRODUCT_CATEGORY_ADD,
  PRODUCT_CATEGORY_REMOVE,
  PRODUCT_CATEGORIES_GET,
  PRODUCT_CATEGORY_UPDATE,
} from './productCategoriesActions'
import {
  add,
  update,
  getAll,
  initialState,
  remove,
  InitialStateType,
} from '../../utils/baseReducers'
import { ProductsActionsCategorys } from './types'

const productsReducer = (
  state: InitialStateType = initialState,
  action: ProductsActionsCategorys
) => {
  switch (action.type) {
    case PRODUCT_CATEGORIES_GET: {
      return getAll(state, action.payload)
    }

    case PRODUCT_CATEGORY_ADD: {
      return add(state, action.payload)
    }

    case PRODUCT_CATEGORY_UPDATE: {
      return update(state, action.payload)
    }

    case PRODUCT_CATEGORY_REMOVE: {
      return remove(state, action.payload)
    }

    default: {
      return state
    }
  }
}

export default productsReducer
