import { Dispatch, AnyAction } from 'redux'
import axios, { makeRequest } from '../../utils/axios'
import { Branch } from './types'

export const BRANCHES_GET = 'BRANCHES_GET'
export const BRANCH_ADD = 'BRANCH_ADD'
export const BRANCH_UPDATE = 'BRANCH_UPDATE'
export const BRANCH_REMOVE = 'BRANCH_REMOVE'

export const getAllBranches = () => async (dispatch: Dispatch<AnyAction>) => {
  const getResponse = await makeRequest({
    axiosRequest: () => axios.get('/branches'),
  })

  if (getResponse) {
    dispatch({
      type: BRANCHES_GET,
      payload: getResponse.data,
    })
  }
}

export const addBranch = (branch: Branch) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const createResponse = await makeRequest({
    axiosRequest: () =>
      axios.post('/branches', {
        name: branch.name,
      }),
    successText: 'Branch added',
  })

  if (createResponse) {
    dispatch({
      type: BRANCH_ADD,
      payload: createResponse.data,
    })
  }
}

export const updateBranch = (branch: Branch) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const updateResponse = await makeRequest({
    axiosRequest: () => axios.patch(`/branches/${branch.id}`, branch),
    successText: 'Branch updated',
  })

  if (updateResponse) {
    dispatch({
      type: BRANCH_UPDATE,
      payload: branch,
    })
  }
}

export const removeBranch = (branchId: Branch['id']) => async (
  dispatch: Dispatch<AnyAction>
) => {
  const deleteResponse = await makeRequest({
    axiosRequest: () => axios.delete(`/branchs/${branchId}`),
    successText: 'Branch removed',
  })

  if (deleteResponse) {
    dispatch({
      type: BRANCH_REMOVE,
      payload: branchId,
    })
  }
}
